import React, { useEffect, useState } from "react";
import "./verify-account.css";
import axios from "../axios";
import { NavLink, useNavigate } from "react-router-dom";
import { logEvent, logPageView } from "../analytics";
import { EVENTS } from "../constants/ga-constants";

export default function VerifyAccount() {
  const [inProcess, setInProcess] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [Form, setForm] = useState([]);
  const [showId1, setShowId1] = useState("");
  const [loading, setLoading] = useState(true);
  const [showId2, setShowId2] = useState("");
  const [activePayNow, setActivePayNow] = useState(false);
  const navigation = useNavigate();
  const [state, setState] = useState({});

  const uploadAdhaarDoc = (e) => {
    try {
      const uploadedFile = e.target.files[0];
      if(!".pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif".includes(uploadedFile.name.split('.').slice(-1)[0])){
        alert("only .pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif format files are allowed")
        return
      }
      if (uploadedFile && uploadedFile.size <= 10 * 1024 * 1024) {
        const { adhaar, id_card, is_paid, advance_amount, is_approved } = {
          ...state,
        };
        updateForm(
          adhaar,
          id_card,
          is_paid,
          advance_amount,
          is_approved,
          true,
          false
        );
        const formData = new FormData();
        formData.append("myImage", uploadedFile);
        axios
          .post(`v1/tutor/tutor-doc-upload`, formData, {
            headers: {
              "content-type": "multipart/form-data",
              "doc-type": "adhaar",
            },
          })
          .then((res) => {
            getVerificationStatusData();
            logEvent(EVENTS.DOC_UPLOAD.event_name);
          })
          .catch((err) => {
            getVerificationStatusData();
            alert("Failed to Upload Document");
          });
      } else {
        alert("Document size should be <= 10 MB");
      }
    } catch (error) {
      alert("Failed to Upload Document");
    }
  };

  const uploadIdDoc = (e) => {
    try {
      const uploadedFile = e.target.files[0];
      if(!".pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif".includes(uploadedFile.name.split('.').slice(-1)[0])){
        alert("only .pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif format files are allowed")
        return
      }
      if (uploadedFile && uploadedFile.size <= 10 * 1024 * 1024) {
        const { adhaar, id_card, is_paid, advance_amount, is_approved } = {
          ...state,
        };
        updateForm(
          adhaar,
          id_card,
          is_paid,
          advance_amount,
          is_approved,
          false,
          true
        );
        const formData = new FormData();
        formData.append("myImage", uploadedFile);
        axios
          .post(`v1/tutor/tutor-doc-upload`, formData, {
            headers: {
              "content-type": "multipart/form-data",
              "doc-type": "id_card",
            },
          })
          .then((res) => {
            getVerificationStatusData();
            logEvent(EVENTS.DOC_UPLOAD.event_name);
          })
          .catch((err) => {
            getVerificationStatusData();
            alert("Failed to Upload Document");
          });
      } else {
        alert("Document size should be <= 10 MB");
      }
    } catch (error) {
      alert("Failed to Upload Document");
    }
  };

  const onInputClick = (event) => {
    event.target.value = null;
  };

  const updateForm = (
    adhaar,
    id_card,
    is_paid,
    advance_amount,
    is_approved,
    loadingId1,
    loadingId2
  ) => {
    setForm([
      {
        icon: "./verification-process-step1-icon.svg",
        title: "Upload Document",
        viewed: true,
        filled: adhaar || id_card,
        fields: [
          {
            show: true,
            name: "step 0",
            input: (
              <div className="step1-form-wrapper">
                <div className="step1-form-desc">
                  Upload any one of your Government ID Proof{" "}
                  <span className="yellow-color">
                    {" "}
                    (eg . Adhar Card / Voter Id card / PAN card / Passport /
                    Driving license){" "}
                  </span>{" "}
                  and submit it to our portal.
                  <br /> Only authenticated verified tutors are enlisted and
                  given opportune tuition's at Home Shiksha.
                </div>

                {!adhaar ? (
                  <form className="step1-form-input-wrapper">
                    <div className="step1-form-input-title">ID Proof-1</div>
                    <label htmlFor="adhaar-1">
                      <div className="step1-form-input-upload">
                        {loadingId1 ? (
                          "Uploading"
                        ) : (
                          <>
                            <img
                              src="./paperclip-icon.svg"
                              alt="paperclip-icon"
                            />
                            Upload
                          </>
                        )}
                      </div>
                    </label>
                    <input
                      id="adhaar-1"
                      type="file"
                      name="myImage"
                      accept=".pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif"
                      onClick={onInputClick}
                      onChange={uploadAdhaarDoc}
                      style={{ display: "none" }}
                    />
                  </form>
                ) : (
                  <form className="step1-form-input-wrapper">
                    <div className="step1-form-input-title">ID Proof-1</div>
                    <label htmlFor="adhaar-1">
                      <div className="step1-form-input-update">
                        {loadingId1 ? (
                          "Uploading"
                        ) : (
                          <>
                            <img
                              src="./paperclip-icon.svg"
                              alt="paperclip-icon"
                            />
                            Update
                          </>
                        )}
                      </div>
                    </label>
                    {!loadingId1 && (
                      <span
                        className="step1-form-input-upload"
                        onClick={() => {
                          setShowId1(adhaar);
                        }}
                      >
                        View
                      </span>
                    )}

                    <input
                      id="adhaar-1"
                      type="file"
                      name="myImage"
                      accept=".pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif"
                      onClick={onInputClick}
                      onChange={uploadAdhaarDoc}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
                {!id_card ? (
                  <form className="step1-form-input-wrapper">
                    <div className="step1-form-input-title">ID Proof-2</div>
                    <label htmlFor="id-card-1">
                      <div className="step1-form-input-upload">
                        {loadingId2 ? (
                          "Uploading"
                        ) : (
                          <>
                            <img
                              src="./paperclip-icon.svg"
                              alt="paperclip-icon"
                            />
                            Upload
                          </>
                        )}
                      </div>
                    </label>
                    <input
                      id="id-card-1"
                      type="file"
                      name="myImage"
                      accept=".pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif"
                      onClick={onInputClick}
                      onChange={uploadIdDoc}
                      style={{ display: "none" }}
                    />
                  </form>
                ) : (
                  <form className="step1-form-input-wrapper">
                    <div className="step1-form-input-title">ID Proof-2</div>
                    <label htmlFor="id-card-1">
                      <div className="step1-form-input-update">
                        {loadingId2 ? (
                          "Uploading"
                        ) : (
                          <>
                            <img
                              src="./paperclip-icon.svg"
                              alt="paperclip-icon"
                            />
                            Update
                          </>
                        )}
                      </div>
                    </label>
                    {!loadingId2 && (
                      <span
                        className="step1-form-input-upload"
                        onClick={() => {
                          setShowId2(id_card);
                        }}
                      >
                        View
                      </span>
                    )}

                    <input
                      id="id-card-1"
                      type="file"
                      name="myImage"
                      accept=".pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif"
                      onClick={onInputClick}
                      onChange={uploadIdDoc}
                      style={{ display: "none" }}
                    />
                  </form>
                )}

                {/* {(res.data.status.adhaar&&res.data.status.id_card)&&<div className='step1-form-input-wrapper'>
                                    <div className='step1-form-input-title'>Adhaar,ID Card </div>
                                    <div className='step1-form-input-upload'>Uploaded</div>
                                </div>} */}
              </div>
            ),
            completed: false,
            errorMsg: "",
          },
        ],
      },
      {
        icon: "./verification-process-step2-icon.svg",
        title: "Submission Of Verification Fee",
        viewed: adhaar || id_card,
        filled: is_paid,
        fields: [
          {
            show: !is_paid,
            name: " step 1",
            input: (
              <div className="step2-form-wrapper">
                <div className="step2-form-payment-img-wrapper">
                  <img src="./payment-icon.svg" alt="payment-icon" />
                </div>
                {!activePayNow ? (
                  <div
                    className="step2-form-paynow-btn"
                    onClick={() => {
                      setActivePayNow(true);
                      payNow();
                    }}
                  >
                    Pay now
                  </div>
                ) : (
                  <div
                    className="step2-form-paynow-btn"
                    onClick={() => {
                      setActivePayNow(false);
                    }}
                  >
                    Reload
                  </div>
                )}
                <div className="step2-form-payment-title-amount">
                  Advance Payment {advance_amount}/-
                </div>
                <div className="step2-form-payment-points-title">
                  <span className="amount">499/-</span> * Exclusive of GST.
                </div>
                <div>
                  <div style={{ margin: "10px 5px" }}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            margin: "8px",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            backgroundColor: "rgba(39, 65, 120, 1)",
                          }}
                        >
                          <img
                            src="./yellow-tic-icon.svg"
                            width={12}
                            alt="points-tic-icon"
                          />
                        </span>
                        <span
                          style={{
                            width: "90%",
                            fontWeight: "500",
                            margin: "5px 0",
                          }}
                        >
                          Get multiple opportunities on a daily basis.
                        </span>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            margin: "8px",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            backgroundColor: "rgba(39, 65, 120, 1)",
                          }}
                        >
                          <img
                            src="./yellow-tic-icon.svg"
                            width={12}
                            alt="points-tic-icon"
                          />
                        </span>
                        <span
                          style={{
                            width: "90%",
                            fontWeight: "500",
                            margin: "5px 0",
                          }}
                        >
                          MoneyBack Guarantee (if you don't get any tuition's
                          matching your profile within 2 months)
                        </span>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            margin: "8px",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            backgroundColor: "rgba(39, 65, 120, 1)",
                          }}
                        >
                          <img
                            src="./yellow-tic-icon.svg"
                            width={12}
                            alt="points-tic-icon"
                          />
                        </span>
                        <span
                          style={{
                            width: "90%",
                            fontWeight: "500",
                            margin: "5px 0",
                          }}
                        >
                          Get high paying tuition opportunities.
                        </span>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            margin: "8px",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            backgroundColor: "rgba(39, 65, 120, 1)",
                          }}
                        >
                          <img
                            src="./yellow-tic-icon.svg"
                            width={12}
                            alt="points-tic-icon"
                          />
                        </span>
                        <span
                          style={{
                            width: "90%",
                            fontWeight: "500",
                            margin: "5px 0",
                          }}
                        >
                          Whatsapp notification
                        </span>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            margin: "8px",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            backgroundColor: "rgba(39, 65, 120, 1)",
                          }}
                        >
                          <img
                            src="./yellow-tic-icon.svg"
                            width={12}
                            alt="points-tic-icon"
                          />
                        </span>
                        <span
                          style={{
                            width: "90%",
                            fontWeight: "500",
                            margin: "5px 0",
                          }}
                        >
                          SMS notification{" "}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            margin: "8px",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            backgroundColor: "rgba(39, 65, 120, 1)",
                          }}
                        >
                          <img
                            src="./yellow-tic-icon.svg"
                            width={12}
                            alt="points-tic-icon"
                          />
                        </span>
                        <span
                          style={{
                            width: "90%",
                            fontWeight: "500",
                            margin: "5px 0",
                          }}
                        >
                          Lifetime validity.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ),
            completed: false,
            errorMsg: "",
          },
          {
            show: is_paid,
            name: "",
            input: (
              <div className="step2-form-wrapper">
                <div className="step2-form-payment-img-wrapper">
                  <img src="./green-tic-icon.svg" alt="green-tic-icon" />
                </div>
                <div className="step2-form-paymentCompleted-desc">
                  Payment Done
                </div>
                <br />
                <div className="step2-form-payment-desc">
                  Congratulations. Welcome to{" "}
                  <span className="yellow-color"> HomeShiksha </span> Family!
                  <br />
                  <br />
                  Your respective city{" "}
                  <span className="yellow-color"> CRE </span> will get in touch
                  with you <span className="yellow-color"> shortly </span>.
                </div>
              </div>
            ),
            completed: false,
            errorMsg: "",
          },
        ],
      },
      {
        icon: "./verification-process-step3-icon.svg",
        title: "Get Set to Earn. Choose tuitions as per your flexibility.",
        viewed: is_paid,
        filled: false,
        fields: [
          {
            show: true,
            name: "",
            input: (
              <div className="step3-form-wrapper">
                <div>
                  <img
                    src="./verification-process-step3-icon.svg"
                    alt="verification-process-step3-icon"
                  />
                </div>
                <div className="step2-form-paynow-btn">
                  <NavLink
                    onClick={() => {
                      navigation("/dashboard");
                      window.location.reload();
                    }}
                  >
                    <span style={{ color: "white" }}>Explore</span>
                  </NavLink>
                </div>
                <div className="step2-form-payment-desc">
                  CRE will assign you for{" "}
                  <span className="yellow-color"> demo classes </span>, you will
                  be notified.
                  <br />
                  <br />
                  Make sure <span className="yellow-color"> accept </span> the
                  invitation.Check out dashboard for available requirements
                  which <span className="yellow-color"> matches </span> your
                  profile !
                </div>
              </div>
            ),
            completed: false,
            errorMsg: "",
          },
          // {
          //     show:false,
          //     name:"",
          //     input:<div></div>,
          //     completed:false,
          //     errorMsg:"",
          // },
        ],
      },
    ]);
  };

  const getVerificationStatusData = () => {
    axios
      .post(`v1/tutor/tutor-doc-status`)
      .then((res) => {
        const { adhaar, id_card, is_paid, advance_amount, is_approved } =
          res.data.status;
        updateForm(
          adhaar,
          id_card,
          is_paid,
          advance_amount,
          is_approved,
          false,
          false
        );
        setState({ adhaar, id_card, is_paid, advance_amount, is_approved });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  };

  useEffect(() => {
    getVerificationStatusData();
  }, [activePayNow]);

  const payNow = () => {
    logEvent(EVENTS.PAY_NOW_BUTTON.event_name);
    axios
      .post("/v1/subscribe-to-plan")
      .then((response) => {
        const requestData = response.data;
        if (requestData.data.includes("https:")) {
          setTimeout(() => {
            window.open(requestData.data, "_blank");
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getVerificationStatusData();
    logEvent(EVENTS.VERIFY_NOW_BUTTON.event_name);
  }, []);

  return !loading ? (
    <main className="main verify-account">
      <dialog open={showId1} className="tc-dialog-box">
        {showId1 &&
        ".jpg,.jpeg,.png,.gif,.mp4,.mov,.avi".includes(
          showId1.split(".").pop().toLowerCase()
        ) ? (
          <img src={showId1} alt="Id Proof 1" width={"100%"} height={"100%"} />
        ) : (
          <>
            <a
              href={showId1}
              target="_blank"
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px",
              }}
              rel="noopener noreferrer"
            >
              Download ID Proof 1
            </a>
          </>
        )}
        <button
          onClick={() => {
            setShowId1("");
          }}
          type="button"
        >
          Close
        </button>
      </dialog>
      <dialog open={showId2} className="tc-dialog-box">
        {showId2 &&
        ".jpg,.jpeg,.png,.gif,.mp4,.mov,.avi".includes(
          showId2.split(".").pop().toLowerCase()
        ) ? (
          <img src={showId2} alt="Id Proof 2" width={"100%"} height={"100%"} />
        ) : (
          <>
            <a
              href={showId2}
              target="_blank"
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px",
              }}
              rel="noopener noreferrer"
            >
              Download ID Proof 2
            </a>
          </>
        )}
        <button
          onClick={() => {
            setShowId2("");
          }}
          type="button"
        >
          Close
        </button>
      </dialog>

      {inProcess ? (
        <div className="verification--wrapper">
          {!Form[Form.length - 1].viewed ? (
            <div className="verify-your-account-title">
              Verification Process
            </div>
          ) : (
            <div className="verify-your-account-title">
              You have been Verified
            </div>
          )}
          <div className="verification-steps-wrapper-container">
            <div className="verify-your-account-steps--wrapper">
              {Form.map((stepItem, stepIndex) => {
                return (
                  <div className="verify-your-account-step-container">
                    <div className="verify-your-account-step-icon--wrapper">
                      <img
                        src={stepItem.icon}
                        alt="profilePic"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="verify-your-account-step-count">
                      Step {stepIndex + 1}
                    </div>
                    {/* <div className='verify-your-account-step--step-desc'>{stepItem.title}</div> */}
                    <div
                      className={`verify-your-account-step-status--wrapper ${
                        stepItem.viewed
                          ? "verify-your-account-step-status-color-border"
                          : "verify-your-account-step-status-blur-border"
                      } `}
                      onClick={() => {
                        if (Form[stepIndex].viewed) {
                          setCurrentStep(() => stepIndex);
                        }
                      }}
                    >
                      {!!stepItem.filled && (
                        <img src="./yellow-tic-icon.svg" alt="profilePic" />
                      )}
                    </div>
                    <div className="verify-your-account-step--step-desc">
                      {stepItem.title}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="verify-your-account-steps--overline"></div>
          </div>
          <div>
            {Form[currentStep].fields.map((field, index) => {
              if (field.show) return field.input;
              else return <div></div>;
            })}
          </div>

          <div className="forms-buttons--wrapper">
            {currentStep < Form.length - 1 && (
              <button
                type="button"
                className="forms-previous-btn"
                onClick={() => {
                  if (currentStep !== 0) setCurrentStep((pre) => pre - 1);
                  if (currentStep === 0) setInProcess(() => false);
                }}
              >
                Previous
              </button>
            )}
            {currentStep < Form.length - 1 && (
              <button
                type="button"
                className="forms-next-btn"
                onClick={() => {
                  if (Form[currentStep].filled) {
                    setForm((oldForm) => {
                      let newForm = [...oldForm];
                      // newForm[currentStep].filled=true
                      newForm[currentStep + 1].viewed = true;
                      return newForm;
                    });
                    setCurrentStep((pre) => pre + 1);
                  }
                }}
              >
                Next
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="verification--wrapper">
          <div className="verify-your-account-title">Verify Your Account</div>
          <div className="verify-your-account-content--wrapper">
            <div className="verify-your-account-steps--wrapper">
              {Form.map((stepItem, stepIndex) => {
                return (
                  <div className="verify-your-account-step-container">
                    <div className="verify-your-account-step-icon--wrapper">
                      <img
                        src={stepItem.icon}
                        alt="profilePic"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="verify-your-account-step-count">
                      Step {stepIndex + 1}
                    </div>
                    <div className="verify-your-account-step--step-desc">
                      {stepItem.title}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="step2-form-payment-desc">
              Verify your <span className="yellow-color"> account </span> to get
              started with <span className="yellow-color"> classes </span>
            </div>
            <div
              className="forms-next-btn verify-your-account-btn-wrapper"
              onClick={() => {
                setInProcess(() => true)
                logEvent(EVENTS.VERIFY_NOW_BUTTON_2.event_name);
              }}
            >
              Verify Now
            </div>
          </div>
          <div className="reviews">
            <div>
              <div>
                <img src="./google-icon.svg" alt="profilePic" />
                <span
                  style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
                  className="reviewer-source-title"
                >
                  {" "}
                  Google
                </span>
              </div>
              <div>
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                Trusted Source{" "}
                <span style={{ color: "black", fontWeight: "500" }}>4.5</span>{" "}
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                <span style={{ color: "black", fontWeight: "500" }}>
                  92,123{" "}
                </span>
                reviews
              </div>
            </div>

            <div>
              <div>
                <img src="./glassdoor-icon.svg" alt="profilePic" />
                <span
                  style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
                  className="reviewer-source-title"
                >
                  {" "}
                  Glassdoor
                </span>
              </div>
              <div>
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                Trusted Source{" "}
                <span style={{ color: "black", fontWeight: "500" }}>4.5</span>{" "}
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                <span style={{ color: "black", fontWeight: "500" }}>
                  92,123{" "}
                </span>
                reviews
              </div>
            </div>

            <div>
              <div>
                <img src="./jd-icon.svg" alt="profilePic" />
                <span
                  style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
                  className="reviewer-source-title"
                >
                  {" "}
                  JustDail
                </span>
              </div>
              <div>
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                Trusted Source{" "}
                <span style={{ color: "black", fontWeight: "500" }}>4.5</span>{" "}
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                <span style={{ color: "black", fontWeight: "500" }}>
                  92,123{" "}
                </span>
                reviews
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div className="loading">
        <img src="./book-loader.gif" alt=" Loading . . .  " />
      </div>
    </div>
  );
}
