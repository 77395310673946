import React, { useEffect } from "react";
import axios from "../axios";
import { useNavigate, useParams } from "react-router-dom";

const WaLogin = () => {
  const { randomString } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      axios
        .post(`v1/generateJWT`, { AuthenticationKey: randomString })
        .then((data) => {
          if (!data.data.is_success) return;
          localStorage.setItem(
            "homeshiksha-tutor-jwt",
            JSON.stringify(data.data.data.token)
          );
          navigate("/dashboard");
          window.location.reload();
        })
        .catch((err) => {
          // console.log(err);
        });
    } catch (error) {
      // console.log(error);
    }
  }, []);

  return (
    <main
      className="main"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <img src="../book-loader.gif" alt=" Loading . . .  " />
    </main>
  );
};

export default WaLogin;
