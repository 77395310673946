import React, { useState } from "react";
import axios from "../axios";
import "./franchiseForm.css";

export default function Franchise() {
  const [showPopUp, setShowPopUP] = useState(false);

  const handleForm = (e) => {
    e.preventDefault();

    const franchiseData = {
      name: e.target["name"].value,
      phone: e.target["phone"].value,
      email: e.target["email"].value,
      location: e.target["location"].value,
      message: e.target["message"].value,
    };

    axios
      .post("/v1/franchise-register", { franchiseData })
      .then((res) => {
        setShowPopUP(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <main className="main franchiseForm">
      <dialog open={showPopUp} className="tc-dialog-box">
        <div
          style={{
            textAlign: "center",
            padding: "20px 0",
            fontWeight: 700,
            fontSize: "22px",
          }}
        >
          Request Submitted Successfully
        </div>

        <button
          onClick={() => {
            setShowPopUP(false);
            window.location.reload();
          }}
          type="button"
        >
          Close
        </button>
      </dialog>

      <h1>
        Own <span className="yellow-color">Franchise</span> of Hyderabad Most
        Trusted Home/Online <span className="yellow-color">Tuition</span>{" "}
        Company
      </h1>

      <section>
        <article className="franchiseForm-article">
          Be a part of our growing story. Earn with us. Give yourself a solid
          foundation in Entrepreneurship with HomeShiksha.
          <br />
          We are the leading provider of Home and Online tutor in Hyderabad and
          Bangalore. HomeShiksha has a mission to make world class
          home-tuitions, online tuitions and e-tuitions accessible and dedicated
          to fulfilling dreams of education across India.
          <br />
          We have more than 17984+ students and 58475+ tutors on-board and with
          you joining we hope that the community grows further strong.
        </article>

        <div className="franchiseForm-title">
          What <span className="yellow-color">we</span> do?
        </div>
        <article className="franchiseForm-article">
          Connects home tutors and online tutors with Students/Customers seeking
          private tuitions of any grades/standards.
          <br />
          Provide home tutor and online tutor for variety of categories such as
          painting, singing, dancing, Yoga, cooking, language learning, special
          children etc.
          <br />
          Provides part time/ full time jobs to an individual and a platform to
          perform their expertise.
        </article>

        <div className="franchiseForm-title">
          Grab the <span className="yellow-color">Opportunity</span>. Own{" "}
          <span className="yellow-color">HomeShiksha</span> Franchise.
        </div>
        <article className="franchiseForm-article">
          HomeShiksha business model has created a successful tutoring platform.
          Currently we provide home and online tuitions across Hyderabad,
          Bangalore, Dehradun, Raipur, Chennai and Vijayawada. We are looking
          for committed franchises to be a part of this exciting business
          venture. We are looking for Franchise across multiple cities in India.
          <br />
          With HomeShiksha processes and methodologies, you will be able to run
          a successful home tutoring and online tutoring venture in your city.
        </article>

        <div className="franchiseForm-title">
          Find some of the <span className="yellow-color">reasons</span> here to
          own <span className="yellow-color">HomeShiksha</span> franchise
        </div>
        <article className="franchiseForm-article">
          <ul>
            <li>Low startup cost and potentially high ROI.</li>
            <li>A growing customer base.</li>
            <li>
              7+ years of experience in handling more than 9000+ students and
              12,000+ tutors.
            </li>
            <li>
              We only provide Master Franchise. That means only One Franchise
              per city.
            </li>
            <li>Recurring revenue model.</li>
            <li>Low franchise fee.</li>
            <li>
              You can start this business from your home first and then have
              small office area of minimum 100 sq. ft when business grows.
            </li>
            <li>Earn from starting 1st month.</li>
            <li>
              No employee required for starting few months. Hire employees as
              you grow.
            </li>
            <li>Comprehensive training and support for your success.</li>
            <li>Strong technical support to bring business for you.</li>
            <li>
              Opportunity to make a difference in the life by helping students.
            </li>
            <li>
              At HomeShiksha, we have a team of experienced business staff and
              technical support staff to help you in running your tutoring
              venture successfully.
            </li>
          </ul>
        </article>

        <div className="franchiseForm-title">
          Here is the list of <span className="yellow-color">support</span> you
          can expect from <span className="yellow-color">HomeShiksha</span>
        </div>
        <article className="franchiseForm-article">
          Technology :
          <ul>
            <li>
              Our inbuilt Customer Relationship Management Software helps you in
              tracking inquiries, leads, teacher/tutor applications city wise.
              It helps to manage tutors and Customers very efficiently.
            </li>
            <li>
              Website for the customers and the teachers to generate leads and
              teacher applications.
            </li>
          </ul>
          <br />
          Extensive training : HomeShiksha provides you extensive training
          program. Our experienced Customer Relationship staff and technical
          support staff will train you and will share you their experience. They
          be helping you with any doubt you get in running your business.
          <br />
          Marketing : Corporate office will do the marketing for you and also
          guide you on your own marketing programs.
          <br />
          Legal documentation
          <br />
          Dedicated professionals from HomeShiksha corporate office will help
          with ongoing operational guidance's and provide you with needed
          support.
        </article>

        <div className="franchiseForm-title">Investment</div>

        <article className="franchiseForm-article">
          Total Investment (Including Brand Fee, Technical Cost, License Cost):
          Rs. 3 to 5 Lakhs. No Hidden Costs.
        </article>

        <div className="franchiseForm-title">
          Contact <span className="yellow-color">Us</span>
        </div>

        <article className="franchiseForm-article">
          HomeShiksha can be your perfect franchise solution.
          <br />
          Call us on: 9000278506 or send us an inquiry in
          franchise@homeshiksha.com to get started.
          <br />
          Website: www.homeshiksha.com
        </article>
      </section>

      <div className="franchiseForm-title">
        Looking for <span className="yellow-color">Franchise</span> in your
        city?
      </div>

      <form onSubmit={handleForm}>
        <div className="small-form-input-component">
          <label htmlFor="form-name">Name</label>
          <input
            type="text"
            placeholder="Enter name"
            name="name"
            id="form-name"
            required
          />
        </div>

        <div className="small-form-input-component">
          <label htmlFor="form-phone">phone</label>
          <input
            type="tel"
            placeholder="Enter phone"
            name="phone"
            id="form-phone"
            required
          />
        </div>

        <div className="small-form-input-component">
          <label htmlFor="form-email">email</label>
          <input
            type="email"
            placeholder="Enter email"
            name="email"
            id="form-email"
            required
          />
        </div>

        <div className="small-form-input-component">
          <label htmlFor="form-city">city</label>
          <input
            type="text"
            placeholder="Enter city"
            name="location"
            id="form-city"
            required
          />
        </div>

        <div className="long-form-input-component">
          <label htmlFor="form-message">message</label>
          <textarea
            id="form-message"
            name="message"
            rows="5"
            cols="33"
            required
          />
        </div>

        <button type="submit">Submit</button>
      </form>
    </main>
  );
}
