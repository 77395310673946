import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { dashboardCardsData } from "../../states/dashboardCardsData";
import { personalData } from "../../states/personalData";
import Autocomplete from "react-google-autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { citysList } from "../../constants/form-templates";
import axios from "../../axios";
import { logEvent } from "../../analytics";

import "./address.css";
import { EVENTS } from "../../constants/ga-constants";

export default function Address() {
  const [address, setAddress] = useAtom(personalData);
  const [cardsCenteralData, setCardsCenteralData] = useAtom(dashboardCardsData);

  const [editRequest, setEditRequest] = useState(false);
  const [loading, setLoading] = useState(true);

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const getPersonalDetails = () => {
    axios
      .get("/v1/user")
      .then((response) => {
        if (response.data.is_success) {
          const data = { ...response.data.data };
          let updatedData = {
            id: data.id,
            name: data.first_name,
            l_name: data.last_name,
            gender: data.gender,
            email: data.email,
            phone: data.phone,
            phone2: data.phone2,
            latitude: data.latitude,
            longitude: data.longitude,
            flat: data.flat === "undefined" ? "" : data.flat,
            apartment: data.apartment === "undefined" ? "" : data.apartment,
            street: data.street === "undefined" ? "" : data.street,
            locality: data.locality === "undefined" ? "" : data.locality,
            pincode: data.zip,
            city: data.city_name,
            state: data.state_name,
            tutor_verified: data.is_active,
            profile_pic_link: data.profile_pic_link
              ? data.profile_pic_link
              : "./person-icon.png",
          };
          setLoading(false);
          setAddress((pre) => ({ ...pre, ...updatedData }));
          logEvent(EVENTS.MY_ADDRESS.event_name);
        } else {
          throw response.data.error;
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (Object.keys(address).length === 0) getPersonalDetails();
    else setLoading(false);
  }, []);

  const setUserAddress = (_newData) => {
    // console.log(_newData);
    axios
      .post(`v1/updateUserDetails`, {
        id: _newData.id,
        first_name: _newData.name,
        last_name: _newData.l_name,
        phone: _newData.phone,
        phone2: _newData.phone2,
        email: _newData.email,
        flat: _newData.flat,
        apartment: _newData.apartment,
        street: _newData.street,
        city_name: _newData.city,
        state_name: _newData.state,
        locality: _newData.locality,
        gmap_meta_data: _newData.gmap_meta_data,
        zip: _newData.pincode,
        latitude: _newData.latitude,
        longitude: _newData.longitude,
        user_type: "tutor",
      })
      .then((data) => {
        getPersonalDetails();
        logEvent(EVENTS.UPDATED_ADDRESS.event_name);
        // console.log("updated user details ");
        return axios.get("/v1/tutor/dashboard-cards");
      })
      .then(async (response) => {
        setCardsCenteralData(response.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value.replace('"', "'"),
    }));
  };

  return address && !loading ? (
    <div className={`address-component ${editRequest ? "editInput" : ""}`}>
      <div className="address-component--wrapper">
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", color: "#2B93A5" }}>
            {/* Address Name : 
                <input type="text" readOnly={!editRequest} name="addressName" value={address.addressName} onChange={handleInputChange} /> */}
          </div>
          <div
            className="personal-info--personal-content-1-right"
            style={{ width: "50%" }}
          >
            {!editRequest ? (
              <div
                onClick={() => {
                  setEditRequest(() => true);
                  // getStates()
                }}
                style={{
                  cursor: "pointer",
                  width: "50px",
                  margin: "0 0 0 auto",
                }}
              >
                Edit
                <img
                  src="/pencil-icon.svg"
                  alt="pencil icon"
                  width="15"
                  height="15"
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {!editRequest ? (
          <div>
            <div>
              Address :{" "}
              <input
                type="text"
                style={{ border: "0" }}
                readOnly={true}
                name=""
                value={
                  address.flat +
                  " " +
                  address.apartment +
                  " " +
                  address.street +
                  " " +
                  address.city +
                  " " +
                  address.state +
                  " " +
                  address.pincode
                }
                // onChange={handleInputChange}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {editRequest ? (
          <>
            <div>
              Flat No :{" "}
              <input
                type="text"
                readOnly={!editRequest}
                name="flat"
                value={address.flat}
                onChange={handleInputChange}
              />
            </div>
            <div>
              Apartment Name :{" "}
              <input
                type="text"
                readOnly={!editRequest}
                name="apartment"
                value={address.apartment}
                onChange={handleInputChange}
              />
            </div>
            <div>
              Street Name :{" "}
              <input
                type="text"
                readOnly={!editRequest}
                name="street"
                value={address.street}
                onChange={handleInputChange}
              />
            </div>
            <div className="city-dropdown">
                City Name :{" "}
                <Select
                  value={address.city}
                  name="city"
                  onChange={handleInputChange}
                  style={{ width: "97%"}}
                >
                  {citysList.map((city) => (
                    <MenuItem value={city}>{city}</MenuItem>
                  ))}
                </Select>
              </div>
            {editRequest && (
              <div>
                Pin Code :
                <Autocomplete
                  apiKey={apiKey}
                  style={{ width: "95%" }}
                  onPlaceSelected={(place) => {
                    let addressComponents = place.address_components;
                    let pinCode = addressComponents.find((component) =>
                      component.types.includes("postal_code")
                    );
                    let locality = addressComponents
                      .map((geo) => geo.long_name)
                      .join(", ");
                    let city = addressComponents.find(
                      (component) =>
                        component.types.includes("locality") &&
                        component.types.includes("political")
                    );
                    let state = addressComponents.find(
                      (component) =>
                        component.types.includes(
                          "administrative_area_level_1"
                        ) && component.types.includes("political")
                    );
                    let latitude = place.geometry.location.lat();
                    let longitude = place.geometry.location.lng();
                    setAddress((prevAddress) => ({
                      ...prevAddress,
                      pincode: pinCode ? pinCode.short_name : " ",
                      state: state ? state.long_name : " ",
                      locality: locality,
                      latitude: latitude,
                      longitude: longitude,
                      gmap_meta_data: JSON.stringify(addressComponents),
                    }));
                  }}
                  options={{
                    types: [
                      // "hospital",
                      // "pharmacy",
                      // "bakery",
                      // "sublocality",
                      // ,
                      // "landmark",
                      // "locality",
                      // "postal_code",
                      // "postal_code_prefix",
                      // "postal_code_suffix",
                      // "postal_town",
                      // "street_address",
                      // "street_number",
                      // "sublocality",
                      // "sublocality_level_1",
                      // "sublocality_level_2",
                      // "sublocality_level_3",
                      // "sublocality_level_4",
                      // "sublocality_level_5",
                      // "subpremise",
                      // "town_square",
                    ],

                    componentRestrictions: { country: "IN" }, // Set country to "IN" for India
                  }}
                  defaultValue={address.pincode}
                />
              </div>
            )}
          </>
        ) : (
          <></>
        )}

        {editRequest ? (
          <div className="showRequest-Save-cancel-wrapper">
            <div
              onClick={() => {
                // setAddress(()=>originalAddress)
                getPersonalDetails();
                setEditRequest(() => false);
              }}
              className="showRequest-Save-wrapper showRequest-cancel-wrapper"
            >
              <button>cancel</button>
            </div>
            <div
              onClick={() => {
                setUserAddress(address);
                setEditRequest(() => false);
              }}
              className="showRequest-Save-wrapper"
            >
              <button>Save</button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div className="loading">
        <img src="./book-loader.gif" alt=" Loading . . .  " />
      </div>
    </div>
  );
}
