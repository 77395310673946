import React, { useEffect, useState, useCallback } from "react";
import "./index.css";
import Autocomplete from "react-google-autocomplete";
import { EVENTS } from "../constants/ga-constants";
import axios from "../axios";
import Faq from "react-faq-component";
import { useNavigate } from "react-router-dom";
import { logEvent, logPageView } from "../analytics";
import { citysList } from "../constants/form-templates";
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const data = {
  // title: "FAQ (How it works)",

  rows: [
    {
      title: "How much will I make once I start tuitions?",
      content: `You could be making anything between Rs.4000 to Rs 40000. It all depends on what you can 
        teach and the tuition requirements of the Parents.`,
    },
    {
      title:
        "How many tuitions I can take? Is there any limit in taking tuitions? ",
      content:
        "You can take as many tuitions as you like. It all depends on your availability and you timings.  There is no limit from HomeShiksha side in providing you tuitions opportunities. ",
    },
    {
      title:
        "What is the difference between verified tutor and unverified tutor? ",
      content: `Unverified Tutor : Upon completion of the your details via form, a tutor becomes an 
        unverified tutor with HomeShiksha. <br>
        Verified Tutor: To become a verified tutor, a tutor must submit or upload their valid 
        Government ID proof and pay the verification fee of Rs. 589 (including GST) which is valif 
        for life time. `,
    },
    {
      title: "Can I pay the verification fee after I receive a requirement?  ",
      content: `Unfortunately, it's not possible as you are supposed to visit students home representing 
        HomeShiksha. Therefore, before proceeding, we need to ensure that the tutor is verified by 
        HomeShiksha.  `,
    },
    {
      title: "What is the policy between HomeShiksha and tutors? ",
      content: `HomeShiksha takes a certain percentage of the tutor's first month tuition fee, from 2nd month 
        the tutor will receive 100% of their tuition fee directly. `,
    },
    {
      title: "Does HomeShiksha provide online tuition or home tuition? ",
      content: `HomeShiksha exclusively offers home tuition, where tutors are required to go to the students 
        houses to teach. `,
    },
    {
      title:
        "After how much time, I will get tuition opportunity once I become a verified tutor?",
      content: `We get day to day tuition requirements on daily basis. You can grab any tuition opportunity 
        as per you subjects you can teach, timing and distance.  You can also grab spot tuition offers 
        which matches your profile.`,
    },
    {
      title:
        " Can I obtain the requirements locally so that I don't have to travel anywhere?",
      content: `You are required to travel to a nearby location within a radius of 5 kilometres. We do our best 
        to provide you with the best tuition opportunity which suits your distance, timing, and your 
        flexibility. `,
    },
  ],
};

const styles = {
  bgColor: "white",
  titleTextColor: "Black",
  rowTitleColor: "Black",
  rowContentColor: "grey",
  arrowColor: "black",
};

const config = {
  animate: true,
  // arrowIcon: "V",
  // tabFocus: true,
  tabFocus: false,
  expandIcon: "+",
  collapseIcon: "-",
};

const customerReviews = [
  {
    reviewTitle: " Very Organised",
    location: "Bangalore,India",
    name: "Geetha Sridhara",
    content:
      "	I got to know about HomeShiksha from a parent and I thought of giving a try for my kid’s tuition. It’s very organised and they gave 3 teachers for demo and I chose the best amongst the three. The team is approachable and provide updates on time. We get good quality learning experience at our home as per our convenient time.",
  },
  {
    reviewTitle: " Very Good Personal Tutor",
    location: "Mumbai,India",
    name: "KanchyKanz",
    content:
      "	Really very happy with this institution as I could get a very good personal tutor for my daughter. Prashanthini has been very good and we could see a difference within a month in our daughter's learning curve. All thanks to Prashanthini who treats our daughter as her own.",
  },
  {
    reviewTitle: " Friendly Staff",
    location: "Hyderabad,India",
    name: "Sowjanya Balireddy",
    content:
      "	Very good institute and friendly staff. We recently hired a shadow teacher from Home shiksha and she has very good knowledge and idea about what she need to work on based on the child needs and requirements. Based on that she stated working on it… which is really good. Thanks home shiksha.. 👍",
  },
  {
    reviewTitle: " Professional Services",
    location: "Bangalore,India",
    name: "Dassan Gita",
    content:
      "	I had contacted Homesiksha for shadow teacher requirement for my child, 6 years old. Ms Moumita was my contact point at Homesiksha Bangalore branch ,She was very professional and attentive to my requirements .I found Homesiksha services very professional, prompt and fuss free. Have recommended their services to my friends aswell.",
  },
  {
    reviewTitle: " Professional Approach",
    location: "Hyderabad,India",
    name: "Aniruddha Banik",
    content:
      " I got instant response upon my queries. They had provided very experienced and good tutor for my 12 years old son who is in CBSE board. Happy yo share that they are excellent in service commitment and have good professional approach towards the services provided. Thank you Home Shiksha for supporting parents like us who need good home tutor",
  },
  {
    reviewTitle: " Excellent Tuitions",
    location: "Bangalore,India",
    name: "Fathima",
    content:
      "	Homeshiksha has connected me to some excellent tuitions which take into consideration the requirements and convenience of both the students, as well as the tutors. 10/10",
  },
  {
    reviewTitle: " Best platform",
    location: "Hyderabad,India",
    name: "Sravanthivd",
    content:
      "	I looked for shadow teacher service..They give the options for us to choose the best for our requirement...Overall the service is good as of now.",
  },
  {
    reviewTitle: " Long Lasting Relationship",
    location: "Bangalore,India",
    name: "Rachel Poovaiah",
    content:
      "	Homeshiksha has been proactive in providing quality teachers in a short span of time.They have coordinated well to understand my needs. Looking forward to have a long lasting relationship with them.",
  },
  {
    reviewTitle: " Good Support",
    location: "Hyderabad,India",
    name: "Sindhuja",
    content:
      "	Home siksha has given me a very good support by searching a qualified teacher who Can match my requirements. They have shown enough patience. Thank you for that # it depends on the teacher though.",
  },
  {
    reviewTitle: " Response Quick",
    location: "Mumbai,India",
    name: "Sheena Mehta",
    content:
      "	After shifting to Mumbai and duped by so many private agencies I gave one last shot by contacting Home shiksha. Believe me they were a life saviour that time . Response time was quick, very professional, polite and humble. If I can add always there to lend an ear to hear your issues. I have finally got two tutors from them and will soon be getting one more for my daughter.. Thankyou Homeshiksha.",
  },
  {
    reviewTitle: " Being So Reliable",
    location: "Hyderabad,India",
    name: "Gautham Kapoor",
    content:
      "	HomeShiksha is a superb platform to learn. I am doing MBA Finance with no commerce/ economics background. So Home Shiksha has been my go to place for past one year to seek great tutors who are guiding me through subjects unfamiliar to me. Thanks for being so reliable",
  },
  {
    reviewTitle: " Great platform",
    location: "Mumbai,India",
    name: "Rahul Chavan",
    content:
      "	Home Shiksha provides great, and useful platform in educational field. I have been grateful to them for arranging tutors, and classes as per our kids requirements.",
  },
  {
    reviewTitle: " Very Responsible",
    location: "Hyderabad,India",
    name: "Radhika Tatiyal",
    content:
      "	I got in touch with Home Shiksha for a teacher and the response by Divya was always prompt. She helped us find a very reliable and responsible teacher as per the requirements. Divya is soft spoken and quickly caught hold of what has to be delivered. Glad about this association. Would highly recommend Home Shiksha to anybody looking for good tutors.",
  },
  {
    reviewTitle: " Very Good Support",
    location: "Hyderabad,India",
    name: "Rajakumara M. N Raaj",
    content:
      "	Very good support for clients and nice result for control behaviour related issues .... They are providing very gud and supportive thepists..... 👏👏👏👏😊😊 …",
  },
  {
    reviewTitle: " Skilled Shadow Teacher",
    location: "Bangalore,India",
    name: "Saroj Das",
    content:
      "	I was looking for shadow teacher and then I connect with Divya. She helped me to get very skilled shadow teacher and are also trained for special need children. I am very happy with the service so far and she also take care of my budget.",
  },
  {
    reviewTitle: " Full Satisfied",
    location: "Hyderabad,India",
    name: "Pooja Behera",
    content:
      "At first I was really scared that how would I explain my Son’s condition as he has ASD to find good teacher for him ,but I am so glad I got connected with HomeShiksha as they helped me a lot , understood the need and accordingly find the best one . Divya was in constant touch unless I am full satisfied With kind of teacher I am looking for. Thank you HomeShiksha!!",
  },
  {
    reviewTitle: " Very Effective",
    location: "Mumbai,India",
    name: "Vaishali Tongay",
    content:
      "	This platform is very good and has been playing an very important and effective role in filling the gap of supply and demand of the tutors and students. The platform and the team itself is very friendly and humble. Great platform to find tutors.",
  },
  {
    reviewTitle: " Good Service",
    location: "Hyderabad,India",
    name: "Vanita Sanap",
    content:
      "	Too good service....understand parents need well and provide all the required support in good price....thanks a lot",
  },
  {
    reviewTitle: " Comfortable Platform",
    location: "Hyderabad,India",
    name: "Shaswe Sha",
    content:
      "	Glad to contact them .They understood my urgent requirement and were real quick to find the best shadow teacher. My kid is real happy comfortable and enjoys learning from her. Best and easy way to find a teacher for your kid.",
  },
  {
    reviewTitle: " Must Try Once",
    location: "Bangalore,India",
    name: "Arti Srivastava",
    content:
      "	Homeshiksha gives tutors the best opportunity and offers based on there qualifications 👍🏻. You guys should definitely try once. …",
  },
];

export default function TutorRegister() {
  const [currentStep, setCurrentStep] = useState(0);
  const [smsOtpVerification, setSmsOtpVerification] = useState(false);

  const [otp, setOtp] = useState();
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [verificationProcess, setVerificationProcess] = useState(false);
  const [showTC, setShowTC] = useState(false);
  const [Form, setForm] = useState([
    {
      viewed: true,
      filled: false,
      fields: [
        {
          type: "text",
          name: "First Name",
          placeHolder: "Enter Your First Name",
          value: "",
        },
        {
          type: "text",
          name: "Last Name",
          placeHolder: "Enter Your Last Name",
          value: "",
        },
        {
          type: "dropDown",
          open: true,
          name: " Your Gender",
          placeHolder: "Select Your Gender",
          options: ["male", "female"],
          value: "",
        },
        {
          type: "tel",
          name: "Mobile",
          placeHolder: "Enter Your 10 Digit Mobile Number",
          value: "",
        },
        {
          type: "email",
          name: "Email",
          placeHolder: "Enter Your Email",
          value: "",
        },
        // {
        //   type: "checkbox",
        //   name: "",
        //   placeHolder: "",
        //   value: false,
        // },
      ],
    },
    {
      viewed: false,
      filled: false,
      fields: [
        {
          type: "dropDown",
          open: true,
          name: "Select Mode of Teaching ?",
          placeHolder: "Select Mode of Teaching",
          options: ["Offline Home Tutions"],
          value: "",
        },
        {
          type: "dropDown",
          open: false,
          name: "Teaching Experience (in years)",
          placeHolder: "Select Teaching Experience",
          options: [
            "Fresher",
            "1 year",
            "2 years",
            "3 years",
            "4 years",
            "5 years",
            "6 years",
            "7 years",
            "8 years",
            "9 years",
            "10 years",
            "11 years",
            "12 years",
            "13 years",
            "14 years",
            "15 years",
            "16 years",
            "17 years",
            "18 years",
            "19 years",
            "20 years",
            "21 years",
            "22 years",
            "23 years",
            "24 years",
            "25 years",
            "26 years",
            "27 years",
            "28 years",
            "29 years",
            "30 years",
            ">30 years",
          ],
          value: "",
        },
        {
          type: "dropDown",
          open: false,
          name: "What you are looking ?",
          placeHolder: "What you are looking ?",
          options: [
            "Looking for Part Time Tuitions",
            "Looking for Full time Teaching Job",
            "Both",
          ],
          value: "",
        },
      ],
    },
    {
      viewed: false,
      filled: false,
      fields: [
        {
          type: "dropDown",
          open: false,
          name: "Vehicle Owned ?",
          placeHolder: "Vehicle Owned ?",
          options: ["Yes", "No"],
          value: "",
        },
        {
          type: "dropDown",
          open: false,
          name: "Which City you are from ?",
          placeHolder: "Select City",
          options: citysList,
          value: "",
        },
        {
          type: "address",
          name: "Location",
          placeHolder: "Location /Pin code / Latitude and Longitude",
          value: "",
        },
        {
          type: "checkbox",
          name: "",
          placeHolder: "",
          value: false,
        },
      ],
    },
    {
      viewed: false,
      filled: false,
      fields: [
        {
          type: "verificationBtn",
          name: "OTP",
          placeHolder: "Enter Your OTP",
          value: "",
        },
      ],
    },
  ]);
  const [showOtpError, setShowOtpError] = useState("");
  const navigate = useNavigate();

  const makeTitle = (string) => {
    try {
      let arr = string.split(" ");
      arr = arr.map((itm) => itm.charAt(0).toUpperCase() + itm.slice(1));
      return arr.join(" ");
    } catch (error) {
      return string;
    }
  };

  const registerAndGetOTP = (login_method = "sms") => {
    let obj = {};
    obj = {
      ...obj,
      first_name: Form[0].fields[0].value,
      last_name: Form[0].fields[1].value,
      gender: Form[0].fields[2].value,
      phone: Form[0].fields[3].value,
      email: Form[0].fields[4].value,

      teching_mode: Form[1].fields[0].value,
      experience: Form[1].fields[1].value,
      teching_type: Form[1].fields[2].value,

      do_you_have_vehicle: Form[2].fields[0].value,

      city_name: Form[2].fields[1].value,
      state_name: Form[2].fields[2].value.state,
      locality: Form[2].fields[2].value.locality,
      gmap_meta_data: Form[2].fields[2].value.gmap_meta_data,
      zip: Form[2].fields[2].value.pincode,
      latitude: Form[2].fields[2].value.latitude,
      longitude: Form[2].fields[2].value.longitude,
    };

    axios
      .post("/v1/tutor-register", obj)
      .then((res) => {
        return axios.post(`v1/generateOtp`, {
          phoneNo: obj.phone,
          login_method,
          user_type: "Tutor",
        });
      })
      .then((data) => {
        data = data.data;
        if (!data.is_success) {
          setShowOtpError(() => data.error.msg);
          return;
        }
        let otp = data.data.otp;
        setOtp(otp);
        if (login_method === "whatsapp") {
          const whatsappLink = `https://wa.me/917386936695?text=Log%20me%20in%20to%20Homeshiksha%20%3A%20H-${otp}`;
          setTimeout(() => {
            window.open(whatsappLink, "_blank");
          });
          checkForJWT("whatsapp", otp);
        }
      })
      .catch((err) => {});
  };

  const checkForJWT = (login_method, otp = "") => {
    let findTokenInterval = setInterval(() => {
      getJWT(findTokenInterval, login_method, otp);
    }, 3000);
    setTimeout(() => {
      clearInterval(findTokenInterval);
      setVerificationProcess(false);
    }, 2 * 60 * 1000);
  };

  const getJWT = async (tokenInterval, login_method, otp) => {
    try {
      let data = await axios.post(`v1/generateJWT`, {
        phoneNo: Form[0].fields[3].value,
        otp: otp,
        login_method,
      });
      if (!data.data.is_success) return;
      localStorage.setItem(
        "homeshiksha-tutor-jwt",
        JSON.stringify(data.data.data.token)
      );
      clearInterval(tokenInterval);
      setVerificationProcess(false);
      navigate("/dashboard");
      window.location.reload();
    } catch (error) {}
  };

  const handleWhatsAppLogin = (e) => {
    e.preventDefault();
    logEvent(EVENTS.WHATSAPP_REGISTER.event_name);
    setVerificationProcess(true);
    registerAndGetOTP("whatsapp");
  };

  const handleVerifyBySMS = () => {
    setSmsOtpVerification(() => true);
    setVerificationProcess(() => true);
    registerAndGetOTP("sms");
    logEvent(EVENTS.SMS_REGISTER.event_name);
    setTimeout(() => {
      setShowResendOtp(() => true);
    }, 2 * 60 * 1000);
  };

  const handleOtpChange = (e) => {
    let otp = e.target.value;
    setOtp(() => otp);
    if (otp.length === 6) {
      setVerificationProcess(() => true);
      axios
        .post(`v1/verifyOtp`, { phoneNo: Form[0].fields[3].value, otp: otp })
        .then((data) => {
          checkForJWT("sms", otp);
        })
        .catch((err) => {});
    } else {
      setVerificationProcess(() => false);
    }
  };

  const handledropDownOptionClick = useCallback(
    (field, index, step, option) => {
      setForm((prevForm) => {
        const newForm = [...prevForm];
        newForm[step].viewed = true;
        newForm[step].fields[index].value = option;

        try {
          if (
            newForm[step].fields[index + 1].type === "dropDown" &&
            newForm[step].fields[index + 1].value === ""
          ) {
            newForm[step].fields[index + 1].open = true;
          }
        } catch (error) {}

        return newForm;
      });
    },
    [setForm]
  );

  const Step = (step) => (
    <div className="form--container">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          let newForm = [...Form];
          let emptyField = [];
          newForm[step].fields.map((inpurField) => {
            if (inpurField.value.length === 0) emptyField.push(inpurField.name);
            if (inpurField.name == "Mobile" && inpurField.value.length < 10)
              emptyField.push(inpurField.name);
            if (inpurField.name == "Mobile" && inpurField.value.length == 10) {
              logEvent(EVENTS.GIVEN_MOBILE_NO.event_name);
            }
            return inpurField;
          });
          if (emptyField.length === 0) {
            logEvent(EVENTS["HS_STEP_NO_" + (step + 1)].event_name);
            setCurrentStep((pre) => step + 1);
            setForm((pre) => {
              newForm[step].filled = true;
              newForm[step + 1].viewed = true;
              try {
                if (
                  newForm[step + 1].fields[0].type === "dropDown" &&
                  newForm[step + 1].fields[0].value === ""
                ) {
                  newForm[step + 1].fields[0] = {
                    ...newForm[step + 1].fields[0],
                    open: true,
                  };
                }
              } catch (error) {}

              return newForm;
            });
          } else {
            setForm((pre) => {
              newForm[step].fields = newForm[step].fields.map((inputField) => {
                if (inputField.value === "")
                  return { ...inputField, open: true };
                return inputField;
              });
              return newForm;
            });
          }
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        {Form[step].fields.map((field, index, stepArray) => {
          let options = field.options;
          let formComponent = <div>input failed </div>;
          switch (field.type) {
            case "dropDown":
              formComponent = (
                <div
                  className="selection--wrapper"
                  onClick={(e) => {
                    setForm((pre) => {
                      let newForm = [...pre];
                      newForm[step].fields[index] = {
                        ...field,
                        open: !field.open,
                      };
                      return newForm;
                    });
                  }}
                  onChange={(e) => {
                    if (!e.target.value) return;
                    handledropDownOptionClick(
                      field,
                      index,
                      step,
                      e.target.value
                    );
                  }}
                >
                  {field.open ? (
                    options.map((option, optionIndex) => (
                      <div
                        className={`selection--option-wrapper ${
                          field.open ? "showDropdown" : "hideDropdown"
                        }`}
                      >
                        <input
                          type="radio"
                          id={`${field.name}-${optionIndex}`} // Ensure unique IDs for each radio button
                          value={option}
                          checked={option === field.value}
                          name={field.name}
                          required
                          style={{ cursor: "pointer" }}
                        />
                        <label
                          htmlFor={`${field.name}-${optionIndex}`}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handledropDownOptionClick(
                              field,
                              index,
                              step,
                              option
                            );
                          }}
                        >
                          {makeTitle(option)}
                        </label>
                      </div>
                    ))
                  ) : field.value !== "" ? (
                    <div className={`selection--option-wrapper`}>
                      <input type="radio" readOnly={true} checked />
                      {makeTitle(field.value)}
                    </div>
                  ) : (
                    field.placeHolder
                  )}
                </div>
              );
              break;
            case "address":
              formComponent = (
                <div>
                  <Autocomplete
                    apiKey={apiKey}
                    style={{ width: "99%" }}
                    onPlaceSelected={(place) => {
                      let addressComponents = place.address_components;
                      let locality = addressComponents
                        .map((geo) => geo.long_name)
                        .join(", ");
                      let pinCode = addressComponents.find((component) =>
                        component.types.includes("postal_code")
                      );
                      let city = addressComponents.find(
                        (component) =>
                          component.types.includes("locality") &&
                          component.types.includes("political")
                      );
                      let state = addressComponents.find(
                        (component) =>
                          component.types.includes(
                            "administrative_area_level_1"
                          ) && component.types.includes("political")
                      );
                      let latitude = place.geometry.location.lat();
                      let longitude = place.geometry.location.lng();
                      let value = {
                        pincode: pinCode ? pinCode.short_name : " ",
                        city: city ? city.long_name : " ",
                        state: state ? state.long_name : " ",
                        locality: locality,
                        latitude: latitude,
                        longitude: longitude,
                        gmap_meta_data: JSON.stringify(addressComponents),
                      };
                      setForm((pre) => {
                        let newForm = [...pre];
                        newForm[step].viewed = true;
                        newForm[step].fields[index] = {
                          ...field,
                          value: value,
                        };
                        return newForm;
                      });
                    }}
                    options={{
                      types: [
                        // "hospital",
                        // "pharmacy",
                        // "bakery",
                        // "sublocality",
                        // ,
                        // "landmark",
                        // "locality",
                        // "postal_code",
                        // "postal_code_prefix",
                        // "postal_code_suffix",
                        // "postal_town",
                        // "street_address",
                        // "street_number",
                        // "sublocality",
                        // "sublocality_level_1",
                        // "sublocality_level_2",
                        // "sublocality_level_3",
                        // "sublocality_level_4",
                        // "sublocality_level_5",
                        // "subpremise",
                        // "town_square",
                      ],
                      componentRestrictions: { country: "IN" }, // Set country to "IN" for India
                    }}
                    placeholder={field.placeHolder}
                  />
                </div>
              );
              break;
            case "verificationBtn":
              if (!smsOtpVerification) {
                formComponent = (
                  <div>
                    <div
                      className="login-btns-wrapper register-btns-wrapper"
                      id="whatsapp-login-btn"
                    >
                      <button
                        type="button"
                        className="registerPrimaryButton"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          handleWhatsAppLogin(e);
                        }}
                      >
                        <img src="./whatsapp-icon.svg" alt="whats app icon" />
                        Verify with Whatsapp
                      </button>
                      <img
                        src="./book-loader.gif"
                        style={{ width: "auto" }}
                        alt=" Loading . . .  "
                        className={`${
                          verificationProcess ? "" : "in"
                        }active-img`}
                      />
                    </div>
                    <div className="login-btns-wrapper register-btns-wrapper">
                      <button
                        type="button"
                        className="registerPrimaryButton"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          handleVerifyBySMS();
                        }}
                      >
                        Verify with Mobile SMS
                      </button>
                      <img
                        src="./book-loader.gif"
                        style={{ width: "auto" }}
                        alt=" Loading . . .  "
                        className={`${
                          verificationProcess ? "in" : "in"
                        }active-img`}
                      />
                    </div>
                  </div>
                );
              } else {
                formComponent = (
                  <>
                    <div
                      className="input-loading-wrapper"
                      style={{ flexDirection: "column" }}
                    >
                      {showOtpError && (
                        <div className="errorAlert">{showOtpError} </div>
                      )}
                      <input
                        type="tel"
                        placeholder="Enter your OTP"
                        className="loginInputField"
                        value={otp}
                        onChange={(e) => {
                          handleOtpChange(e);
                        }}
                        style={{ textAlign: "center" }}
                      />
                      <div className="genetating-otp-loader">
                        {verificationProcess && (
                          <img src="./book-loader.gif" alt=" Loading . . .  " />
                        )}
                      </div>
                    </div>
                    <div className="login-btns-wrapper">
                      <div>
                        {showResendOtp && (
                          <button
                            type="button"
                            className="loginPrimaryButton"
                            onClick={() => {
                              handleVerifyBySMS();
                            }}
                          >
                            Resend OTP
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                );
              }
              break;
            case "checkbox":
              formComponent = (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    required
                    type={field.type}
                    id={`${field.name}`}
                    name={`${field.name}`}
                    placeholder={`${field.placeHolder}`}
                    value={field.value}
                    onChange={(e) => {
                      let value = !field.value;
                      setForm((pre) => {
                        let newForm = [...pre];
                        newForm[step].viewed = true;
                        newForm[step].fields[index] = {
                          ...field,
                          value: value,
                        };
                        return newForm;
                      });
                    }}
                  />
                  <span style={{ marginLeft: "8px", fontWeight: "600" }}>
                    I have read and accept{" "}
                    <span
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setShowTC(true);
                      }}
                      style={{
                        color: "navy",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      Tutor Terms and Conditions.
                    </span>
                  </span>
                  <dialog open={showTC} className="tc-dialog-box">
                    <br />
                    <br />
                    1. Your status must be an Indian citizen. You understand
                    that Home Shiksha is a home tutoring marketplace and will
                    facilitate through its platform and other resources finding
                    for you such students as who want home tuition in your area
                    of expertise.
                    <br />
                    <br />
                    2. A Tutor warrants that he is of at least Twenty years of
                    age, and having necessary qualifications experience to give
                    tutoring in the Categories/Syllabus and subjects specified
                    by the Tutor on his/her profile.
                    <br />
                    <br />
                    3. For Home Tuitions from Bangalore, Mumbai, Gurgaon,
                    Dehradun, Raipur, Chennai and Vijayawada: HomeShiksha will
                    charge you an upfront of only 50% of the tuition fees as a
                    commission upon student/parent’s confirmation. The 1st month
                    50% of tuition fees tutor will get in the month ending. Also
                    all subsequent months 100% of tuition fees will be yours.
                    For Home Tuitions Hyderabad Region: HomeShiksha will charge
                    you an upfront of 1 month tuition fees as a commission upon
                    student/parent’s confirmation. The subsequent months 100% of
                    tuition fees will be yours. For Online Tuitions from
                    Hyderabad, Bangalore, Mumbai, Gurgaon, Dehradun, Raipur,
                    Chennai and Vijayawada: HomeShiksha will charge you an
                    upfront of 1 month tuition fees as a commission upon
                    student/parent’s confirmation. The subsequent months 100% of
                    tuition fees will be yours. You authorize Home Shiksha to
                    collect payment on your behalf in consideration of the home
                    tuition delivered. You agree that all the tax liability for
                    this payment is yours. You understand and agree that
                    HomeShiksha will deduct its commission from the payment
                    received
                    <br />
                    <br />
                    4. As a tutor providing home tuitions, you agree to comply
                    with all applicable laws, exercise the same degree of
                    professional competence, care, skill, diligence and prudence
                    as is normally exercised by professionals in the home-
                    tutoring field.
                    <br />
                    <br />
                    5. You may receive information proprietary to Home Shiksha.
                    You understand and agree that confidential information so
                    received will only be used by you for the benefit of Home
                    Shiksha and for the purpose of providing tutoring.
                    <br />
                    <br />
                    6. You indemnify Home Shiksha from and against any and all
                    claims, causes of action, and liabilities which arise
                    directly from breach of any applicable law, for your
                    negligent or willful misconduct.
                    <br />
                    <br />
                    7. You understand and agree that the relationship between
                    Home Shiksha and you will not be considered as an employer
                    and employee relation.
                    <br />
                    <br />
                    8. You understand that the final decision to choose a
                    particular student for tuition is yours and yours alone. In
                    case you are selected in a demo, you can’t reject the
                    tuition until and unless there are authentic reason. In the
                    event where you are unable to attend the tuition, a prior
                    notice of 48 hours before the lesson starts, must be given
                    to the students/parents.
                    <br />
                    <br />
                    9. You understand and agree that you will teach the student
                    if and only if at least one parent is present in the house.
                    If you are leaving any tuition, you must intimate us prior
                    to 1 week. HOME SHIKSHA will not be liable for any conflict
                    or disagreement between the tutors and students/ parents. We
                    will also not be responsible for any form of damage caused
                    in connection with your use of our website.
                    <br />
                    <br />
                    10. The subscription charges for a tutor are only Rs. 499/-,
                    and it's valid for a lifetime. We offer a Moneyback
                    Guarantee (if you don't get any tuitions matching your
                    profile within 2 months).
                    <br />
                    <br />
                    11. The tutor must have convincing teaching skills and good
                    behavior with student & parents.
                    <br />
                    <br />
                    12. Selected Tutors do not share their selected tuition
                    details with their friends / colleagues and do not invite
                    them to the student's residence at the time of introduction
                    / Demo class.
                    <br />
                    <br />
                    13. A tutor here by acknowledges that Home Shiksha are not
                    liable to the Tutor for reimbursement of any fees paid
                    pursuant to these Terms in the events of any cancellation of
                    any Contract or Tuition for any reason.
                    <br />
                    <br />
                    14. You here by confirm that you will abide all the rules
                    and regulations of Home Shiksha. And in case if you get
                    caught cheating with Home Shiksha then Home Shiksha can take
                    any legal action on you. Any attempt to cheat or deprive
                    Home Shiksha.com of its legal share of commission, will
                    result in legal actions being taken.
                    <br />
                    <br />
                    15. Home Shiksha reserves all the rights to change all or
                    any of these above mentioned Terms and Conditions with or
                    without giving any prior notice at any time.
                    <br />
                    <br />
                    16. You may not solicit, collect, give or use the login
                    credentials of other users of the Service.
                    <br />
                    <br />
                    17. You may not impersonate any other person or entity or to
                    use a false name or a name that you have no authority to
                    use.
                    <br />
                    <br />
                    18. You are responsible for keeping your password secret and
                    secure.
                    <button
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setShowTC(false);
                      }}
                    >
                      Got It
                    </button>
                  </dialog>
                </div>
              );
              break;
            default:
              formComponent = (
                <input
                  required
                  type={field.type}
                  id={`${field.name}`}
                  name={`${field.name}`}
                  placeholder={`${field.placeHolder}`}
                  value={field.value}
                  onChange={(e) => {
                    let value = e.target.value;
                    setForm((pre) => {
                      let newForm = [...pre];
                      newForm[step].viewed = true;
                      newForm[step].fields[index] = {
                        ...field,
                        value: value,
                      };
                      return newForm;
                    });
                  }}
                />
              );
          }

          return (
            <div key={index}>
              <div className="input-group">
                {field.name && (
                  <label htmlFor={`${field.name}`}>
                    {!field.open ? field.name : field.placeHolder}
                  </label>
                )}
                {formComponent}
              </div>
            </div>
          );
        })}

        <div className="form-buttons-wrapper">
          {currentStep > 0 && currentStep < 3 && (
            <button
              className="secondary-btn"
              type="button"
              onClick={(e) => setCurrentStep(step - 1)}
            >
              Previous
            </button>
          )}
          {currentStep < 3 && (
            <button className="primary-btn" type="submit">
              Next
            </button>
          )}
          {/* {currentStep === 5 && <button className="primary-btn" type="button">Save</button>} */}
        </div>
      </form>
    </div>
  );

  function getRandomRGBColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b},.35)`;
  }

  useEffect(() => {
    logPageView("Register Page");
    let jwt = JSON.parse(localStorage.getItem("homeshiksha-tutor-jwt"));
    if (!jwt) {
      localStorage.setItem("userDetails", JSON.stringify({}));
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/dashboard");
      window.location.reload();
    }
  }, []);

  return (
    <main className="main need-a-tutor">
      <section className="top-session">
        <div className="need-a-tutor-top-session--rightpart-wrapper">
          <div className="need-a-tutor-top-session--rightpart--form-wrapper">
            <div className="form_title">Become a tutor</div>
            <div className="form-status--wrapper">
              <div className="steps-wrapper">
                {Form.map((item, index) => (
                  <div className="step-status-wrapper" key={index}>
                    <div
                      onClick={(e) => {
                        if (Form[index].viewed) setCurrentStep((pre) => index);
                      }}
                    >
                      Step {index + 1}{" "}
                    </div>
                    <div
                      onClick={(e) => {
                        if (Form[index].viewed) setCurrentStep((pre) => index);
                      }}
                      className={`step-status-circle ${
                        Form[index].viewed ? "blue-border" : ""
                      } ${Form[index].filled ? "blue-bg" : ""}`}
                    ></div>
                  </div>
                ))}
              </div>
              <div className="line-wrapper-under-steps-wrapper"></div>
            </div>
            <div className="form--wrapper">{Step(currentStep)}</div>
          </div>
        </div>
        <div className="need-a-tutor-top-session--leftpart-wrapper">
          <div className="need-a-tutor-biglogo--wrapper">
            <img src="./tutor-main-side-pic.svg" alt="tutor-main-side-pic" />

            <div className="need-a-tutor-biglogo--title">
              Elevating{" "}
              <span style={{ color: "rgba(242, 192, 12, 1)" }}>Education</span>,
              <br /> One{" "}
              <span style={{ color: "rgba(39, 65, 120, 1)" }}>Home</span> at a
              Time
            </div>
          </div>
        </div>
      </section>

      <div className="reviews tutor-page-reviews">
        <div>
          <div>
            <img src="./google-icon.svg" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
              Google
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.8</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>1,013+ </span>
            reviews
          </div>
        </div>

        <div>
          <div>
            <img src="./jd-icon.svg" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
              JustDail
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.7</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>827+ </span>
            reviews
          </div>
        </div>

        <div>
          <div>
            <img src="./mouth-shut-icon.gif" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.5</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>1177+ </span>
            reviews
          </div>
        </div>

        <div>
          <div>
            <img src="./glassdoor-icon.svg" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
              Glassdoor
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.8</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>50+ </span>
            reviews
          </div>
        </div>
      </div>

      <section className="certified-by-india-section">
        <div className="certified-by-india-section--title">
          Certified by Indian Government
        </div>
        <div className="certified-by-india-section--midline"></div>
        <div className="certified-by-india-section--imgs-wrapper">
          <div>
            <img src="./msme-img.png" alt="msme img" />
          </div>
          <div>
            <img src="./startupindia-img.png" alt="start up india img" />
          </div>
        </div>
      </section>

      <section className="small-section">
        <div className="small-blue-border"></div>
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">USP</div>
            <div>
              HomeShiksha is the leading provider of private tutoring.We are
              rapidly growing organisation that offers huge possibilities for
              your career advancement.
            </div>
          </div>
          <div className="small-section--text-right">
            HomeShiksha has a mission to make world class home-tuitions, online
            tuitions and e-tuitions accessible and dedicated to fulfill dreams
            of education across India.We help find learning options for students
            as well as adults in academic and non academic area.
          </div>
        </div>
      </section>

      <section className="small-section">
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">
              Our service is 100% free to parents and students.
            </div>
          </div>
        </div>
        <div>
          HomeShiksha is the leading provider of private tutoring.We are rapidly
          growing organisation that offers huge possibilities for your career
          advancement.
        </div>

        <div className="small-section--text">
          <div className="small-section--text-left">
            <div style={{ margin: "15px 0" }}>
              HomeShiksha is the leading provider of private tutoring.We are
              rapidly growing organisation that offers huge possibilities for
              your career advancement.
            </div>
          </div>
        </div>

        <div className="service-points-wrapper">
          <div className="service-points-wrapper--left-part">
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "8px",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(39, 65, 120, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                More than 15000+ tutors/ teachers are earning from us.
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "8px",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(39, 65, 120, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                Teach what you are passionate about. Academic or Curricular.
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "8px",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(39, 65, 120, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                Get part time or full time oppurtunites
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "8px",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(39, 65, 120, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                Rated 4.7+ star in google, justdial and mouthshut
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "8px",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(39, 65, 120, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                Teach in your free time{" "}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "8px",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(39, 65, 120, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                Teach as per your timing and locagtions.
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "8px",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(39, 65, 120, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                Competitive pay rates
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "8px",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(39, 65, 120, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                Safety and Security of both tutors and students are our top
                priority
              </div>
            </div>
          </div>
          <div className="service-points-wrapper--right-part">
            <img
              src="./tutor-second-main-img.png"
              width={450}
              alt="tutor-second-main-img"
            />
          </div>
        </div>
      </section>

      <section className="small-section">
        <div className="small-blue-border"></div>
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">
              How <span style={{ color: "rgba(242, 192, 12, 1)" }}>it</span>{" "}
              works?
            </div>
            <div>
              HomeShiksha is the leading provider of private tutoring.We are
              rapidly growing organisation that offers huge possibilities for
              your career advancement.
            </div>
          </div>
          <div className="small-section--text-right">
            HomeShiksha has a mission to make world class home-tuitions, online
            tuitions and e-tuitions accessible and dedicated to fulfill dreams
            of education across India.We help find learning options for students
            as well as adults in academic and non academic area.
          </div>
        </div>
      </section>

      <section className="how-it-works--wrapper">
        <div className="how-it-works-parts">
          <div className="how-it-works-step--wrapper">
            <div className="how-it-works-step--image-wrapper">
              <div
                className="how-it-works-step--image--container"
                style={{ backgroundColor: "white" }}
              >
                <img src="./tutor-step-1-icon.svg" alt="tutor-step-1-icon" />
                <img
                  className="how-it-works-step--curve--image down-curve"
                  src="./down-curve-arrow.svg"
                  alt="down-curve-arrow"
                />
              </div>
            </div>
            <div className="how-it-works-step--content--container">
              <div
                className="step-no"
                style={{ color: "rgba(242, 192, 12, 1)" }}
              >
                01
              </div>
              <div className="step-title">Step</div>
              <div className="step-desc">Give your Requirements</div>
            </div>
          </div>

          <div className="how-it-works-step--wrapper">
            <div className="how-it-works-step--content--container">
              <div
                className="step-no"
                style={{ color: "rgba(242, 192, 12, 1)" }}
              >
                02
              </div>
              <div className="step-title">Step</div>
              <div className="step-desc">
                DOORSTEP/ONLINE Our executive will provide the expert online
                tutor or home tutor at your doorstep for free.
              </div>
            </div>
            <div className="how-it-works-step--image-wrapper">
              <div
                className="how-it-works-step--image--container"
                style={{ backgroundColor: "white" }}
              >
                <img
                  src="./tutor-step-2-icon.svg"
                  alt="tutor-step-2-icon pic"
                />
                <img
                  className="how-it-works-step--curve--image up-curve"
                  src="./up-curve-arrow.svg"
                  alt="up-curve-arrow pic"
                />
              </div>
            </div>
          </div>

          {/* </div>

                <div className="how-it-works-parts"> */}

          <div className="how-it-works-step--wrapper">
            <div className="how-it-works-step--image-wrapper">
              <div
                className="how-it-works-step--image--container"
                style={{ backgroundColor: "white" }}
              >
                <img
                  src="./tutor-step-3-icon.svg"
                  alt="tutor-step-3-icon pic"
                />
              </div>
            </div>
            <div className="how-it-works-step--content--container">
              <div
                className="step-no"
                style={{ color: "rgba(242, 192, 12, 1)" }}
              >
                03
              </div>
              <div className="step-title">Step</div>
              <div className="step-desc">
                FREE DEMO Take free demo class from our expert/verified tutors
              </div>
            </div>
          </div>

          {/* <div className="how-it-works-step--wrapper">
                    <div className="how-it-works-step--content--container">
                          <div className="step-no" style={{color:"rgba(242, 192, 12, 1)"}}>04</div>
                          <div className="step-title">Step</div>
                          <div className="step-desc">CONFIRM Choose the expert tutor and start the tuition and be free from your worries...</div>
                    </div>
                    <div className="how-it-works-step--image-wrapper">
                        <div className="how-it-works-step--image--container" style={{backgroundColor:"white"}} >
                            <img src="./Mountains.svg" alt="mountains pic" />
                        </div>
                    </div>
                  </div> */}
        </div>
      </section>

      <section className="small-section">
        <div className="small-blue-border"></div>
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">
              Frequent{" "}
              <span style={{ color: "rgba(242, 192, 12, 1)" }}>asked</span>{" "}
              questions{" "}
            </div>
            {/* <div>is simply dummy text of the printing and typesetting industry.</div> */}
          </div>
          {/* <div className="small-section--text-right">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's is simply dummy text of the printing and typesetting industry</div> */}
        </div>
      </section>

      <section>
        <Faq data={data} styles={styles} config={config} />
      </section>

      <section className="small-section">
        <div className="small-blue-border"></div>
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">
              Customer{" "}
              <span style={{ color: "rgba(242, 192, 12, 1)" }}>say</span>
            </div>
            {/* <div>is simply dummy text of the printing and typesetting industry.</div> */}
          </div>

          {/* <div className="small-section--text-right">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's is simply dummy text of the printing and typesetting industry</div> */}
        </div>
      </section>

      <section className="customer-say--slider-cards">
        <div className="customer-say-slider--wrapper">
          {customerReviews.map((review) => (
            <div
              className="customer-say-slider-card"
              style={{ backgroundColor: getRandomRGBColor() }}
            >
              <div className="customer-say-slider-card--main-container">
                <div className="customer-say-slider-card-title">
                  {review.reviewTitle}
                </div>
                <div className="customer-say-slider-card-desc">
                  {review.content}
                </div>
              </div>

              <div className="customer-say-slider-card--small-container">
                <div className="customer-say-slider-card--img-wrapper">
                  <img src="./Mountains.svg" alt="mountains pic" />
                </div>
                <div className="customer-say-slider-card--customer--info-wrapper">
                  <div className="customer-say-slider-card--customer-name">
                    {review.name}
                  </div>
                  <div className="customer-say-slider-card-desc">
                    {review.location}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* <section className="small-section">
        <div className="small-blue-border"></div>
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">Download our app!</div>
            <div>is simply dummy text of the printing and typesetting industry.</div>
          </div>
          <div className="small-section--text-right">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's is simply dummy text of the printing and typesetting industry is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's is simply dummy text of the printing and typesetting industry</div>
        </div>
      </section> */}

      {/* <section className="footer-section"></section> */}
    </main>
  );
}
