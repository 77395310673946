import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { dashboardCardsData } from "../../states/dashboardCardsData";
import axios from "../../axios";
import { allCourses } from "../../states/allCourses";
import { courseStep } from "../../states/allCourses";
import { useNavigate } from "react-router-dom";
import { logEvent,logPageView } from "../../analytics";
import "./mycourses.css";
import { EVENTS } from "../../constants/ga-constants";

export default function MyCourses() {
  const [cardsCenteralData, setCardsCenteralData] = useAtom(dashboardCardsData);
  const [fullCourseTemplate, setFullCourseTemplate] = useAtom(allCourses);
  const [step, setStep] = useAtom(courseStep);
  const navigation = useNavigate();

  const handleCourseChange = (course, dropdownFormIndx, oldDropDownForm) => {
    let updatedCourseTemplate = [...fullCourseTemplate];
    const index =
      updatedCourseTemplate[dropdownFormIndx]?.selected_courses.indexOf(course);

    if (course === "All Subjects" && index === -1) {
      updatedCourseTemplate[dropdownFormIndx].selected_courses =
        updatedCourseTemplate[dropdownFormIndx].src_courses;
    } else if (course === "All Subjects") {
      updatedCourseTemplate[dropdownFormIndx].selected_courses = [];
    } else if (index === -1) {
      updatedCourseTemplate[dropdownFormIndx].selected_courses.push(course);
    } else {
      const AllCoursesIndex =
        updatedCourseTemplate[dropdownFormIndx]?.selected_courses.indexOf(
          "All Subjects"
        );
      updatedCourseTemplate[dropdownFormIndx].selected_courses.splice(index, 1);
      if (AllCoursesIndex !== -1)
        updatedCourseTemplate[dropdownFormIndx].selected_courses.splice(
          AllCoursesIndex,
          1
        );
    }
    setFullCourseTemplate(updatedCourseTemplate);
    //   // console.log(updatedCourseTemplate);
  };

  const returnCourses = () => {
    let resultCourseFroms = [];
    fullCourseTemplate.map(
      (dropdownForm, dropdownFormIndx, oldDropDownForm) => {
        let Title = dropdownForm.title;
        if (dropdownFormIndx <= 2 && dropdownFormIndx >= 1) {
          Title = "Primary / Pre-Primary / LKG / UKG";
          dropdownForm.src_courses = ["All Subjects"];
        } else if (dropdownFormIndx >= 3 && dropdownFormIndx <= 7) {
          Title = "1st - 5th Classes";
          dropdownForm.src_courses = [
            "All Subjects",
            "All Subjects Except Language",
            ...dropdownForm.src_courses.filter(
              (course) =>
                !["All Subjects", "All Subjects Except Language"].includes(
                  course
                )
            ),
          ];
        } else if (dropdownFormIndx >= 8 && dropdownFormIndx <= 10) {
          Title = "6th - 8th Classes";
          dropdownForm.src_courses = [
            "All Subjects",
            "All Subjects Except Language",
            ...dropdownForm.src_courses.filter(
              (course) =>
                !["All Subjects", "All Subjects Except Language"].includes(
                  course
                )
            ),
          ];
        } else if (dropdownFormIndx >= 11 && dropdownFormIndx <= 12) {
          dropdownForm.src_courses = [
            ...dropdownForm.src_courses.filter(
              (course) =>
                !["All Subjects", "All Subjects Except Language"].includes(
                  course
                )
            ),
          ];
          // Title="9th - 10th Classes"
        } else if (dropdownFormIndx >= 32 && dropdownFormIndx <= 35) {
          Title = "Special Kid( ADHD/ADD/AUTISM )";
        }
        resultCourseFroms.push(
          <form
            key={dropdownFormIndx}
            className={`form--wrapper ${
              [0, 2, 4, 5, 6, 7, 9, 10, 33, 34, 35].includes(dropdownFormIndx)
                ? "none-display"
                : ""
            }`}
          >
            <div
              className={`form-heading ${
                dropdownForm.opened ||
                dropdownForm.selected_courses.length !== 0
                  ? "active-form-heading"
                  : "inactive-form-heading"
              }`}
              onClick={() => {
                let updatedCourseTemplate = [...fullCourseTemplate];
                updatedCourseTemplate = updatedCourseTemplate.map(
                  (form, indx) => {
                    if (indx !== dropdownFormIndx) form.opened = false;
                    return form;
                  }
                );
                updatedCourseTemplate[dropdownFormIndx].opened =
                  !dropdownForm.opened;
                setFullCourseTemplate(updatedCourseTemplate);
              }}
            >
              {Title}
              {!dropdownForm.opened &&
              dropdownForm.selected_courses.length === 0 ? (
                <img
                  src="./down-arrow-grey-icon.svg"
                  alt="down-arrow-grey-icon"
                />
              ) : dropdownForm.opened ? (
                <img src="./up-arrow-grey-icon.svg" alt="up-arrow-grey-icon" />
              ) : (
                <img
                  src="./down-arrow-white-icon.svg"
                  alt="down-arrow-white-icon"
                />
              )}
            </div>
            <div
              className={`${dropdownForm.opened && "form-options--wrapper"}`}
            >
              {dropdownForm.opened &&
                dropdownForm.src_courses.map((course) => (
                  <div
                    key={dropdownForm.title + course}
                    className={`form-option`}
                  >
                    <label style={{ cursor: "pointer" }}>
                      <input
                        type="checkbox"
                        checked={dropdownForm.selected_courses.includes(course)}
                        onChange={() => {
                          const handleCourseChangeForRange = (start, end) => {
                            for (let i = start; i <= end; i++) {
                              handleCourseChange(course, i, oldDropDownForm);
                            }
                          };

                          if (dropdownFormIndx >= 1 && dropdownFormIndx <= 2) {
                            handleCourseChangeForRange(1, 2);
                          } else if (
                            dropdownFormIndx >= 3 &&
                            dropdownFormIndx <= 7
                          ) {
                            handleCourseChangeForRange(3, 7);
                          } else if (
                            dropdownFormIndx >= 8 &&
                            dropdownFormIndx <= 10
                          ) {
                            handleCourseChangeForRange(8, 10);
                          } else if (
                            dropdownFormIndx >= 32 &&
                            dropdownFormIndx <= 35
                          ) {
                            handleCourseChangeForRange(32, 35);
                          } else {
                            handleCourseChange(
                              course,
                              dropdownFormIndx,
                              oldDropDownForm
                            );
                          }
                        }}
                      />
                      {course}
                    </label>
                  </div>
                ))}
            </div>
          </form>
        );
        return dropdownForm;
      }
    );

    return (
      <div className={`all-forms-wrapper`}>
        <div className="left-forms-wrapper">
          {resultCourseFroms.slice(
            0,
            Math.ceil((fullCourseTemplate.length + 5) / 2)
          )}
        </div>
        <div className="right-forms-wrapper">
          {resultCourseFroms.slice(
            Math.ceil((fullCourseTemplate.length + 5) / 2)
          )}
        </div>
      </div>
    );
  };

  const showselected_courses = () => {
    let resultCourseFroms = [];
    fullCourseTemplate.map(
      (dropdownForm, dropdownFormIndx, oldDropDownForm) => {
        let title = dropdownForm.title;
        switch (title) {
          case "Primary / Pre-Primary":
            title = "Primary / Pre-Primary / LKG / UKG";
            break;
          case "1st Class":
            title = "1st - 5th Classes";
            break;
          case "6th Class":
            title = "6th - 8th Classes";
            break;
          case " Special Kid ( 2 years to 4 years )":
            title = "Special Kid( ADHD/ADD/AUTISM )";
            break;
          default:
        }
        if (
          dropdownForm.selected_courses.length !== 0 &&
          ![
            "7th Class",
            "8th Class",
            "LKG / UKG",
            "2nd Class",
            "3rd Class",
            "4th Class",
            "5th Class",
            " Special Kid ( 5 years to 8 years )",
            " Special Kid ( 9 years to 12 years )",
            " Special Kid ( 13 years to 16 years )",
          ].includes(dropdownForm.title)
        )
          resultCourseFroms.push(
            <form key={dropdownFormIndx} className="form--wrapper">
              <div className={`form-heading active-form-heading`}>{title}</div>
              <div className={`form-options--wrapper`}>
                {dropdownForm.selected_courses.map((course) => (
                  <div
                    key={dropdownForm.title + course}
                    className={`form-option`}
                  >
                    <label style={{ cursor: "pointer" }}>
                      <input type="radio" checked={true} onChange={() => {}} />
                      {course}
                    </label>
                  </div>
                ))}
              </div>
            </form>
          );
        return dropdownForm;
      }
    );

    return (
      <div className={`all-forms-wrapper`}>
        <div className="left-forms-wrapper">
          {resultCourseFroms.slice(0, Math.ceil(resultCourseFroms.length / 2))}
        </div>
        <div className="right-forms-wrapper">
          {resultCourseFroms.slice(Math.ceil(resultCourseFroms.length / 2))}
        </div>
      </div>
    );
  };

  const saveTutorCourses = () => {
    axios
      .post("/v1/tutor/update-courses", {
        updatedCoursesData: fullCourseTemplate.filter(
          (dropdownForm) => dropdownForm.selected_courses.length !== 0
        ),
      })
      .then((response) => {
        fetchAndSetAllCourses();
        return axios.get("/v1/tutor/dashboard-cards");
      })
      .then(async (response) => {
        setCardsCenteralData(response.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
    setStep(3);
    if (window.location.pathname === "/my-courses") navigation("/dashboard");
  };

  const fetchAndSetAllCourses = () => {
    axios
      .get("/v1/tutor/all-courses")
      .then((response) => {
        setFullCourseTemplate(response.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    logEvent(EVENTS.UPDATED_Course.event_name);
    if (fullCourseTemplate.length === 0) {
      fetchAndSetAllCourses();
    }
  }, []);

  return (
    <main className="my-courses-component-wrapper">
      <div className="my-courses-component--main-title">
        {step == 1 ? "Syllabus Information" : "Classes Information"}
      </div>
      <div className="my-courses-component--sub-title">
        {step == 1
          ? "select the syllabus you teach"
          : step == 2
          ? "Your Selected Course & Subjects"
          : "Choose Your Preferred Subjects & Courses "}
      </div>
      <div className="forms-with-btn-container">
        {step === 0 ? (
          <>
            {returnCourses()}
            <div className="my-courses-component-btns-wrapper">
              <div
                className="primary-btn"
                onClick={() => {
                  if (
                    fullCourseTemplate.find(
                      (dropdown) =>
                        [1, 4].includes(dropdown.category_id) &&
                        dropdown.selected_courses.length !== 0
                    )
                  )
                    setStep(1);
                  else setStep(2);
                }}
              >
                Next
              </div>
            </div>
          </>
        ) : step === 1 ? (
          <>
            <form
              key={0}
              className={`form--wrapper`}
              style={{ margin: " 0 auto", width: "95%" }}
            >
              <div className={`form-heading active-form-heading`}>
                {fullCourseTemplate[0].title}
                <img src="./up-arrow-grey-icon.svg" alt="up-arrow-grey-icon" />
              </div>
              <div className={`form-options--wrapper`}>
                {fullCourseTemplate[0].src_courses.map((course) => (
                  <div key={"0"} className={`form-option`}>
                    <label style={{ cursor: "pointer" }}>
                      <input
                        type="checkbox"
                        checked={fullCourseTemplate[0].selected_courses.includes(
                          course
                        )}
                        onChange={() => {
                          handleCourseChange(course, 0, fullCourseTemplate);
                        }}
                      />
                      {course}
                    </label>
                  </div>
                ))}
              </div>
            </form>

            <div className="my-courses-component-btns-wrapper">
              <div
                className="secondary-btn"
                onClick={() => {
                  setStep(0);
                }}
              >
                Previous
              </div>
              <div
                className="primary-btn"
                onClick={() => {
                  setStep(2);
                }}
              >
                Next
              </div>
            </div>
          </>
        ) : step === 2 ? (
          <>
            {showselected_courses()}
            <div className="my-courses-component-btns-wrapper">
              <div
                className="secondary-btn"
                onClick={() => {
                  if (
                    fullCourseTemplate.find(
                      (dropdown) =>
                        [1, 4].includes(dropdown.category_id) &&
                        dropdown.selected_courses.length !== 0
                    )
                  )
                    setStep(1);
                  else setStep(0);
                }}
              >
                Previous
              </div>
              <div
                className="primary-btn"
                onClick={() => {
                  saveTutorCourses();
                  logEvent(EVENTS.SAVE_COURSES.event_name);
                }}
              >
                Save
              </div>
            </div>
          </>
        ) : (
          step === 3 && (
            <>
              {showselected_courses()}
              <div className="my-courses-component-btns-wrapper">
                <div
                  className="primary-btn"
                  onClick={() => {
                    setStep(0);
                    logEvent(EVENTS.ADD_COURSES.event_name);
                  }}
                >
                  Add Courses
                </div>
              </div>
            </>
          )
        )}
      </div>
    </main>
  );
}
