import React, { useEffect } from 'react'
import './support.css'
import Faq from "react-faq-component";
import { logEvent } from '../analytics';
import { EVENTS } from '../constants/ga-constants';


export default function Support() {

    const data = {
        // title: "FAQ (How it works)",
        
        rows: [
            {
                title: "Shadow Teacher Support",
                content: (
                  <>
                    Phone: <a href='tel:08121020332'>+91-8121020332</a><br />
                    Email: <a href='mailto:shadow@homeshiksha.com'>shadow@homeshiksha.com</a><br/><br/>
                  </>
                ),
              },
            {
                title: "Hyderabad Office",
                content: (
                  <>
                    Phone: <a href='tel:09642222147'>+91-9642222147</a>,<a href='tel:09133416664'>+91-9133416664</a> <br />
                    Email: <a href='mailto:contact@homeshiksha.com'>contact@homeshiksha.com</a><br/><br/>
                  </>
                ),
              },
            {
                title: "Bengaluru Office",
                content: (
                  <>
                    Phone: <a href='tel:09133201333'>09133201333</a> <br />
                    Email: <a href='mailto:shadow_support@homeshiksha.com'> shadow_support@homeshiksha.com</a><br/><br/>
                  </>
                ),
              },
            {
                title: "Dehradun / Ahmedabad Office",
                content: (
                  <>
                    Phone: <a href='tel:09515222917'>09515222917</a> <br />
                    Email: <a href='mailto:contact_dun@homeshiksha.com'> contact_dun@homeshiksha.com</a><br/><br/>
                  </>
                ),
              },
            {
                title: "Delhi Office",
                content: (
                  <>
                    Phone: <a href='tel:09515777838'>09515777838</a> <br />
                    Email: <a href='mailto:contact_delhi@homeshiksha.com'> contact_delhi@homeshiksha.com</a><br/><br/>
                  </>
                ),
              },
            {
                title: "Mumbai Office",
                content: (
                  <>
                    Phone: <a href='tel:07416903501'>07416903501</a> <br />
                    Email: <a href='mailto:contact_mumbai@homeshiksha.com'> contact_mumbai@homeshiksha.com</a><br/><br/>
                  </>
                ),
              },
            {
                title: "Chennai Office",
                content: (
                  <>
                    Phone: <a href='tel:07981641754'>07981641754</a> <br />
                    Email: <a href='mailto:contact_chennai@homeshiksha.com'> contact_chennai@homeshiksha.com</a><br/><br/>
                  </>
                ),
              },
            {
                title: "Pune Office",
                content: (
                  <>
                    Phone: <a href='tel:09652333903'>09652333903</a> <br />
                    Email: <a href='mailto:contact_pune@homeshiksha.com'> contact_pune@homeshiksha.com</a><br/><br/>
                  </>
                ),
              },
              
            
            
        ],
      };
      
      const styles = {
        bgColor: 'white',
        titleTextColor: "Black",
        rowTitleColor: "Black",
        rowContentColor: 'grey',
        arrowColor: "black",
      };
      
      const config = {
        animate: true,
        // arrowIcon: "V",
        tabFocus: true,
        expandIcon: "+",
        collapseIcon: "-",
      };
      useEffect(()=>{
        logEvent(EVENTS.SUPPORT_CLICKED.event_name);
      },[])

  return (
    <main className='main'>
        
        {/* <section className='pathway-requestBtn--wrapper'>
        <div className='pathway--wrapper'>
          <div>
                    <img
                    src="/homeicon.svg"
                    alt="homeicon icon"
                    width="18"
                    height="17"
                    />
          </div>
          <div>
                  <img
                    src="/rightArrow.svg"
                    alt="rightArrow icon"
                    width="11"
                    height="11"
                    />
          </div>
          <div className='pathway--btn'>Support</div>
        </div>
      </section> */}


      <section className='support-cards-wrapper'>

            <div className='support-card-wrapper'>
                <div className='support-card-img-wrapper'>
                    <img src='./support-icon-1.svg' alt='support-icon-1'/>
                </div>
                <div className='support-card-wrapper-title'>We're here to help</div>
                <div className='support-card-wrapper-desc'>Encountered an issue or have a question? Our dedicated team at liberty program is ready to assist you.</div>
                {/* <div className='support-card-wrapper-desc'></div> */}
                    
            </div>
            

            {/* <div className='support-card-wrapper'>
                <div className='support-card-img-wrapper'>
                    <img src='./support-icon-2.svg' alt='support-icon-2'/>
                </div>
                <div className='support-card-wrapper-title'>Immediate assistance? Chat with us</div>
                <div className='support-card-wrapper-desc'>Click on the chat icon to chat with one of our support agent.</div>
                    
            </div> */}
            

            <div className='support-card-wrapper'>
                <div className='support-card-img-wrapper'>
                    <img src='./support-icon-3.svg' alt='support-icon-3'/>
                </div>
                <div className='support-card-wrapper-title'>Reach out directly</div>
                <div className='support-card-wrapper-desc'>Prefer to send us an email or give us a call?</div>
                {/* <div className='support-card-wrapper-desc'>Email:<span style={{color:"rgba(234, 185, 9, 1)"}}> support@mail.com</span></div>
                <div className='support-card-wrapper-desc'> phone:<span style={{color:"rgba(234, 185, 9, 1)"}}> +91 9876543210</span></div> */}
                    
            </div>
            

            {/* <div className='support-card-wrapper'>
                <div className='support-card-img-wrapper'>
                    <img src='./support-icon-4.svg' alt='support-icon-4'/>
                </div>
                <div className='support-card-wrapper-title'>Share your experience</div>
                <div className='support-card-wrapper-desc'>Encountered an issue or have a question? Our dedicated team at </div>
                <div className='support-card-wrapper-desc'> liberty program is ready to assist you.</div>
                    
            </div> */}
            
           

            
           
            

      </section>


      {/* <section className="small-section">
                      <div className="small-blue-border" style={{backgroundColor:"rgba(242, 192, 12, 1)"}}></div>
                      <div className="small-section--text">
                        <div className="small-section--text-left">
                          <div className="small-section--text-left--title">Frequent <span style={{color:"rgba(242, 192, 12, 1)"}}>asked</span> questions </div>
                          <div>is simply dummy text of the printing and typesetting industry.</div>
                        </div>
                        <div className="small-section--text-right">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's is simply dummy text of the printing and typesetting industry</div>
                      </div>
      </section> */}
      
         

      <section className='support-contacts'>
              <Faq
                data={data}
                styles={styles}
                config={config}
            />
      </section>





    </main>
  )
}
