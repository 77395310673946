import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { personalData } from "../states/personalData";
import { allCourses } from "../states/allCourses";
import { alertData } from "../states/alert";
import { dashboardCardsData } from "../states/dashboardCardsData";
import StudentCard from "../components/student-card";
import { NavLink, useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import axios from "../axios";
import { logEvent, logPageView } from "../analytics";
const currentDate = new Date();
const currentHour = currentDate.getHours();

export default function Dashboard() {
  const [fullCourseTemplate, setFullCourseTemplate] = useAtom(allCourses);
  const [cardsCenteralData, setCardsCenteralData] = useAtom(dashboardCardsData);
  const [personalCenteralData, setPersonalCenteralData] = useAtom(personalData);
  const [subNavigation, setSubNavigation] = useState("recommended");
  const navigation = useNavigate();
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useAtom(alertData);

  const getPersonalDetails = () => {
    axios
      .get("/v1/user")
      .then((response) => {
        if (response.data.is_success) {
          const data = { ...response.data.data };
          let updatedData = {
            id: data.id,
            name: data.first_name,
            l_name: data.last_name,
            gender: data.gender,
            email: data.email,
            phone: data.phone,
            phone2: data.phone2 === "null" ? "" : data.phone2,
            latitude: data.latitude,
            longitude: data.longitude,
            flat: data.flat === "undefined" ? "" : data.flat,
            apartment: data.apartment === "undefined" ? "" : data.apartment,
            street: data.street === "undefined" ? "" : data.street,
            locality: data.locality === "undefined" ? "" : data.locality,
            pincode: data.zip,
            city: data.city_name,
            state: data.state_name,
            // tutor_verified: data.is_active,
            profile_pic_link: data.profile_pic_link
              ? data.profile_pic_link
              : "./person-icon.png",
          };
          // // console.log(updatedData);
          // setSubNavigation((data.is_active)?'mydemo':'recommended')
          setPersonalCenteralData((pre) => ({ ...pre, ...updatedData }));
          setLoading(false);
        } else {
          setLoading(true);
          throw response.data.error;
        }
      })
      .catch((err) => {
        setLoading(true);
        // console.log(err);
      });

    axios
      .post(`v1/tutor/tutor-doc-status`)
      .then((res) => {
        const {
          is_approved,
          adhaar,
          id_card,
          is_paid,
          advance_amount,
          cv_file,
          resume_file,
          intro_video,
        } = res.data.status;
        setPersonalCenteralData((pre) => ({
          ...pre,
          tutor_verified: is_paid,
          adhaar,
          id_card,
          is_paid,
          advance_amount,
          cv_file,
          resume_file,
          intro_video,
        }));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(true);
        // console.log(error);
      });
  };

  const timeOfDay = () => {
    let timeOfDay;

    if (currentHour >= 5 && currentHour < 12) {
      timeOfDay = "Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      timeOfDay = "Afternoon";
    } else {
      timeOfDay = "Evening";
    }
    return timeOfDay;
  };

  const getAppliedJobs = () => {
    axios
      .get("/v1/tutor/applied-jobs")
      .then((response) => {
        if (response.data.is_success) {
          setCardsCenteralData((currentDate) => {
            const AppliedJobIds = response.data.data;
            const newDate = currentDate.map((card) => {
              if (AppliedJobIds.indexOf(parseInt(card.dealings_id)) !== -1)
                return { ...card, isActive: true };
              return card;
            });
            return newDate;
          });
        }
      })
      .catch((err) => {
        setLoading(true);
      });
  };

  const getSubNavCount = (domain) =>
    cardsCenteralData.filter((item) => item.card_domain === domain).length;

  const SubNavigationLink = ({ domain, text }) => (
    <div
      onClick={() => setSubNavigation(domain)}
      className={`${
        subNavigation === domain ? "active-sub-link" : "unactive-sub-link"
      }`}
    >
      {text}
      {getSubNavCount(domain) !== 0 && (
        <span className="sub-nav-notification">{getSubNavCount(domain)}</span>
      )}
    </div>
  );

  const fillCourseTemplate = () => {
    axios
      .get("/v1/tutor/all-courses")
      .then((response) => {
        let fullCourseTemplate = response.data.data;
        setFullCourseTemplate(fullCourseTemplate);
        if (
          fullCourseTemplate.filter(
            (dropdownForm) => dropdownForm.selected_courses.length !== 0
          ).length === 0
        )
          navigation("/my-courses");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  };

  useEffect(() => {
    logPageView("tutor-dashboard-page");
    const jwt = JSON.parse(localStorage.getItem("homeshiksha-tutor-jwt"));
    if (!jwt) {
      navigation("/");
      window.location.reload();
      return;
    }

    // to get personal info
    if (Object.keys(personalCenteralData).length === 0) {
      getPersonalDetails();
    }

    //  to get all the couses from DB
    if (fullCourseTemplate.length === 0) {
      fillCourseTemplate();
    } else {
      //  to get tutor selected courses only
      setLoading(false);
      const resultCourseFroms = fullCourseTemplate.filter(
        (dropdownForm) => dropdownForm.selected_courses.length !== 0
      );
      if (resultCourseFroms.length === 0) navigation("/my-courses");
    }

    // to get all the session details and recomended session details
    if (Object.keys(cardsCenteralData).length === 0) {
      axios
        .get("/v1/tutor/dashboard-cards")
        .then(async (response) => {
          // console.log(response.data.data);
          setCardsCenteralData(response.data.data);
          getAppliedJobs();
        })
        .catch((err) => {
          setLoading(true);
        });
    }
  }, []);

  return !loading ? (
    <main className="main">
      <section className="greating-section-wrrapper">
        <div className="greating-section--profile--wrapper">
          <img
            src={personalCenteralData.profile_pic_link}
            alt="user profile"
            width="100%"
          />
        </div>

        <div className="greating-section--profile-content-wrapper">
          <div className="greatings-title">
            Good {timeOfDay()} {personalCenteralData.name} !
          </div>
          <div className="greatings-desc">
            {!personalCenteralData.tutor_verified
              ? "Unverified Tutor"
              : "You Are Verified Tutor"}{" "}
          </div>

          {!!personalCenteralData.tutor_verified && (
            <img
              className="verified-tutor-status-icon"
              src="./verified-tutor-status-icon.svg"
              alt="verified-tutor-status-icon"
              width="15px"
            />
          )}
        </div>
      </section>

      {alert && (
        <Alert
          severity="success"
          onClose={() => {
            setAlert(false);
          }}
        >
          {alert}
        </Alert>
      )}

      {!personalCenteralData.tutor_verified && (
        <section className="verify-now-section--wrapper">
          <div className="verify-now-section--left--wrapper">
            <div className="verify-now-section--left--warning-icon-wrapper">
              !
            </div>
            <div>Verify your account to get started with classes</div>
          </div>

          <div className="verify-now-section--right--wrapper">
            <NavLink to="/verify-account">Verify Now</NavLink>
          </div>
        </section>
      )}

      <section className="subnavigation-section-wrapper">
        <div className="subnavigation-section--titles--wrapper">
          <SubNavigationLink domain="recommended" text="Recommended" />
          <SubNavigationLink domain="mydemo" text="My Demo" />
          <SubNavigationLink domain="myclasses" text="My classes" />
        </div>

        <div className="subnavigation-section-content--wrapper">
          <div
            className={`subnavigation-section-left-content--wrapper ${
              personalCenteralData.tutor_verified
                ? "subnavigation-section-left-content--wrapper-verified"
                : "subnavigation-section-left-content--wrapper-unverified"
            }`}
          >
            {[
              ...cardsCenteralData.filter(
                (itm) => itm.card_domain === subNavigation
              ),
            ].length === 0
              ? "No Deal is Assigned Or Match to your courses "
              : [
                  ...cardsCenteralData.filter(
                    (itm) => itm.card_domain === subNavigation
                  ),
                ].map((itm, indx) => (
                  <StudentCard key={indx} cardId={itm.id} />
                ))}
          </div>
          {!personalCenteralData.tutor_verified && (
            <div className="subnavigation-section-right-content--wrapper">
              <div className="subnavigation-section-right-bigPic">
                <img
                  src="https://homeshiksha.blr1.cdn.digitaloceanspaces.com/uat/users/profile-pictures/1F3jkxwuH1rM-pexels-katerina-holmes.png"
                  alt="pre watch img "
                  height="100%"
                />
              </div>
              <div className="subnavigation-section-right-smallPic">
                <img
                  src="./small-pre-watch-pic.png"
                  alt="pre watch img "
                  height="100%"
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </main>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div className="loading">
        <img src="./book-loader.gif" alt=" Loading . . .  " />
      </div>
    </div>
  );
}
