import React from 'react'
import StudentCard from '../components/student-card'
import { useAtom } from 'jotai';
import { NavLink,useNavigate } from 'react-router-dom';
import { dashboardCardsData } from '../states/dashboardCardsData';
import './student.css'
export default function Student() {
    const [cardsCenteralData,setCardsCenteralData]=useAtom(dashboardCardsData)
    const navigate = useNavigate();
  return (
    <main className='main'>

        <section className='pathway-requestBtn--wrapper'>
            <div className='pathway--wrapper'>
            <div onClick={()=>{navigate ('/dashboard')}}>
                        <img
                        src="/homeicon.svg"
                        alt="homeicon icon"
                        width="18"
                        height="17"
                        />
            </div>
            <div>
                    <img
                        src="/rightArrow.svg"
                        alt="rightArrow icon"
                        width="11"
                        height="11"
                        />
            </div>
            <div className='pathway--btn'>Student</div>
            </div>
        </section>

        <div className='students--container--nav-wrapper'>
            <div  className='title' >Your Confirmed Tuitions</div>
        </div>
        <div className='subnavigation-section-content--wrapper student-page'>
                {([...cardsCenteralData.filter((itm)=>(itm.card_domain==='myclasses'||itm.card_domain==='mydemo'))].length>0)
                ?
                    <div className={`subnavigation-section-left-content--wrapper subnavigation-section-left-content--wrapper-verified`}>
                        {[...cardsCenteralData.filter((itm)=>(itm.card_domain==='myclasses'||itm.card_domain==='mydemo'))].map((itm,indx)=><StudentCard key={indx} cardId={itm.id} />)}
                        {/* {cardsCenteralData.map((itm,indx)=><StudentCard key={indx} cardId={itm.id} />)} */}
                    </div>
                :
                    
                    <div className='emptyTutors-container'>
                        No Confirmed Tuitions Yet
                    </div>
                        
                }
        </div>



    </main>
  )
}
