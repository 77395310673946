import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { personalData } from "../states/personalData";
import { NavLink, useNavigate } from "react-router-dom";

export default function Navigation() {
  const [activePage, setActivePage] = useState("dashboard");
  const [showMobileNavigation, setShowMobileNagigation] = useState(false);
  const [personalCenteralData, setPersonalCenteralData] = useAtom(personalData);

  const navigation = useNavigate();

  return (
    <nav className="full-top-navigation-bar-wrapper">
      <div className="top-bar-mobile-navigation--wrapper">
        <div className="top-bar-profile-notification-btn-wrapper">
          <div>
            <img
              src="/bell-notification.svg"
              alt="Bell Notification"
              width="20"
              height="20"
            />
          </div>
          <div className="">
            <img
              src="./menu-bar-icon.svg"
              alt="user profile"
              // width="35"
              height="25"
              onClick={() => {
                setShowMobileNagigation(true);
              }}
            />
          </div>
        </div>

        <div
          className={`top-bar-mobile-navigation ${
            showMobileNavigation ? "open-mobile-navigation" : ""
          }`}
        >
          <div
            className="top-bar-mobile-navigation-close-btn"
            onClick={() => {
              setShowMobileNagigation(() => false);
            }}
          >
            Close
          </div>
          <div className="top-bar-navigation--titles--container">
            <NavLink
              to="/dashboard"
              className={`${activePage === "dashboard" ? "" : "un"}active-link`}
              onClick={() => {
                setShowMobileNagigation(() => false);
                setActivePage(() => "dashboard");
              }}
            >
              Dashboard
            </NavLink>
            <NavLink
              to="/student"
              className={`${activePage === "student" ? "" : "un"}active-link`}
              onClick={() => {
                setShowMobileNagigation(() => false);
                setActivePage(() => "student");
              }}
            >
              Student
            </NavLink>
            {/* <NavLink to='/classes' className={`${(activePage==='classes')?'':"un"}active-link`} onClick={()=>{setShowMobileNagigation(()=>false);setActivePage(()=>'classes')}}>Classes</NavLink> */}
            <NavLink
              to="/personal-info"
              className={`${
                activePage === "personal-info" ? "" : "un"
              }active-link`}
              onClick={() => {
                setShowMobileNagigation(() => false);
                setActivePage(() => "personal-info");
              }}
            >
              Personal Info
            </NavLink>
            <NavLink
              to="/address"
              className={`${activePage === "address" ? "" : "un"}active-link`}
              onClick={() => {
                setShowMobileNagigation(() => false);
                setActivePage(() => "address");
              }}
            >
              Address
            </NavLink>
            <NavLink
              to="/my-courses"
              className={`${
                activePage === "my-courses" ? "" : "un"
              }active-link`}
              onClick={() => {
                setShowMobileNagigation(() => false);
                setActivePage(() => "my-courses");
              }}
            >
              My Courses
            </NavLink>
            <NavLink
              to="/faqs"
              className={`${activePage === "faqs" ? "" : "un"}active-link`}
              onClick={() => {
                setShowMobileNagigation(() => false);
                setActivePage(() => "faqs");
              }}
            >
              FAQ'S
            </NavLink>
            <NavLink
              to="/support"
              className={`${activePage === "support" ? "" : "un"}active-link`}
              onClick={() => {
                setShowMobileNagigation(() => false);
                setActivePage(() => "support");
              }}
            >
              Support
            </NavLink>
          </div>
          <div className="top-bar-navigation--logout-btn--wrapper">
            <div
              onClick={() => {
                localStorage.removeItem("homeshiksha-tutor-jwt");
                navigation("/");
                window.location.reload();
              }}
            >
              Logout
            </div>
          </div>
        </div>
      </div>
      <div className="top-bar-navigation--wrapper">
        <div className="top-bar-navigation--titles--container">
          <NavLink
            to="/dashboard"
            className={`${activePage === "dashboard" ? "" : "un"}active-link`}
            onClick={() => {
              setActivePage(() => "dashboard");
            }}
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/student"
            className={`${activePage === "student" ? "" : "un"}active-link`}
            onClick={() => {
              setActivePage(() => "student");
            }}
          >
            Student
          </NavLink>
          {/* <NavLink to='/classes' className={`${(activePage==='classes')?'':"un"}active-link`} onClick={()=>{setActivePage(()=>'classes')}}>Classes</NavLink> */}
        </div>

        <div className="top-bar-profile-notification-btn-wrapper">
          <div>
            <img
              src="/bell-notification.svg"
              alt="Bell Notification"
              width="20"
              height="20"
            />
          </div>
          <div className="navigation-profile-pic-wrapper">
            <NavLink to="/my-profile">
              <img
                src={personalCenteralData.profile_pic_link}
                alt="user profile"
                width="35"
                // height="40"
              />
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}
