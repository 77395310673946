import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Button,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import { logEvent,logPageView } from "../analytics";
import axios from "../axios";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  gender: "",
  comment: "",
  counselling_type: "",
};

export default function Counselling() {
  const [formData, setFormData] = useState(initialValues);
  const [registered, setRegistered] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setRegistered(true);
    axios
      .post(`v1/register-counselling`, { counsellingData: formData })
      .then((response) => {
        const requestData = response.data;
        setFormData(initialValues);
        if (requestData.data.includes("https:")) {
          setTimeout(() => {
            window.open(requestData.data, "_blank");
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    logPageView("counselling page")
    axios
      .get(`v1/user`)
      .then((res) => {
        const { first_name, last_name, gender, email, phone } = res.data.data;
        setFormData({ first_name, last_name, gender, email, phone });
      })
      .catch((err) => {
      });
  }, []);
  return (
    <main className="main franchiseForm">
      <h1>
        Get Personalized Education Guidance{" "}
        <span className="yellow-color"> Unlock </span>
        <br />
        Your Child's Potential with our{" "}
        <span className="yellow-color">One-on-One</span> Sessions
      </h1>

      <section className="counselling-top-section">
        <Box sx={{ flexGrow: 1 }} className="counselling-slider">
            <img
              style={{
                display: "block",
                overflow: "hidden",
                width: "100%",
              }}
              src={"./counselling-icon.png"}
              alt=""
            />
        </Box>

        {!registered && (
          <div className="counselling-form-wrapper counselling-form">
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <TextField
                label="Full Name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
              <TextField
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <TextField
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <FormControl>
                <InputLabel>Gender</InputLabel>
                <Select
                  native
                  id="gender"
                  label="Gender"
                  name="gender"
                  required
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option aria-label="None" value="" />
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>Counselling Type</InputLabel>
                <Select
                  native
                  id="grouped-native-select"
                  label="Counselling Type"
                  name="counselling_type"
                  onChange={handleChange}
                  required
                >
                  <option aria-label="None" value="" />
                  <optgroup label="Academic Counseling">
                    <option value="Preprimary Curriculum Selection">
                      Preprimary Curriculum Selection
                    </option>
                    <option value="Choosing the Right Course After 10th">
                      Choosing the Right Course After 10th
                    </option>
                    <option value="Choosing the Right Course After 12th">
                      Choosing the Right Course After 12th
                    </option>
                    <option value="Study Abroad Counseling">
                      Study Abroad Counseling
                    </option>
                  </optgroup>
                  <optgroup label="Special Kids Counselling">
                    <option value="Choosing the Best Inclusive School for Special Kids">
                      Choosing the Best Inclusive School for Special Kids
                    </option>
                  </optgroup>
                  <optgroup label="Career Counselling">
                    <option value="Selection of Job after graduation">
                      Selection of Job after graduation
                    </option>
                    <option value="Selection of Job after Post-graduation">
                      Selection of Job after Post-graduation
                    </option>
                  </optgroup>
                </Select>
              </FormControl>

              <TextField
                label="Comment"
                name="comment"
                multiline
                required
                rows={4}
                value={formData.comment}
                onChange={handleChange}
              />

              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#274178" }}
              >
                Submit
              </Button>
            </form>
          </div>
        )}
        {registered && (
          <h1
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#0b810b",
            }}
          >
            Your Request has been submited
          </h1>
        )}
      </section>

      <section>
        <div className="franchiseForm-title">
          Choose the Right Syllabus for Your{" "}
          <span className="yellow-color">Pre-Primary</span> Child : <wbr />
          Guiding Parents Towards a{" "}
          <span className="yellow-color"> Bright </span> Future
        </div>

        <article className="franchiseForm-article">
          As parents, nurturing your child's educational journey from the very
          beginning is paramount. The right foundation during pre-primary years
          sets the stage for a successful academic future. At HomeShiksha, we
          understand the importance of selecting the appropriate syllabus that
          aligns with your child's unique needs and future aspirations.
          <br />
          At HomeShiksha, we sculpt futures from the start. Our personalized one
          on one guide sessions help parents select the perfect syllabus,
          ensuring a foundation that not only meets today's standards but
          propels your child toward a brilliant tomorrow. Invest in their
          success - start the journey with us.
          <ul>
            <br />
            <li>
              Personalized Guidance to navigate syllabus options like CBSE,
              ICSE, State Boards, and more.
            </li>
            <li>
              Comprehensive Understanding of different syllabuses (CBSE, ICSE,
              State Boards) and their implications.
            </li>
            <li>
              Future-Oriented Choices Aligning with your child's unique needs
              and future goals.
            </li>
            <li>
              Ensuring the chosen syllabus (CBSE, ICSE, State Boards) meets
              current standards and propels your child toward a brilliant
              tomorrow.
            </li>
          </ul>
          Invest in your child's educational journey today with our ONE ON ONE
          guide sessions. Together, let's pave the way for a bright and
          successful future.
        </article>
      </section>

      <section>
        <div className="franchiseForm-title">
          Choosing the Best Inclusive School for{" "}
          <span className="yellow-color">Special Kids</span> in your city :
          Empowering Parents for <span className="yellow-color">Inclusive</span>{" "}
          Education
        </div>

        <article className="franchiseForm-article">
          For parents with special needs children, finding the right school that
          provides a supportive and inclusive environment is crucial. At
          HomeShiksha, we recognize the importance of inclusive education and
          are committed to guiding parents in their quest for the best
          educational setting for their special kids.
          <br />
          Navigate the landscape of inclusive education effortlessly with
          HomeShiksha. Our guide sessions provide a roadmap to schools that
          champion inclusivity. Connect with a supportive community, access
          expert consultations, and make informed choices for your special
          child's extraordinary journey.
          <ul>
            <br />
            <li>
              Tailored guidance to children with diverse challenges, including
              learning disabilities, autism, ADHD, speech and language
              disorders, and more.
            </li>
            <li>
              Personalized roadmap to schools that champion inclusivity for
              special needs children.
            </li>
            <li>
              Choosing a school that nurtures and includes, ensuring an
              enriching experience.
            </li>
          </ul>
          <br />
          Choosing the right school for your special kid is a crucial step
          toward ensuring a nurturing and inclusive educational experience. Let
          HomeShiksha be your partner in this journey.
          <br />
          Invest in your special child's journey today with our ONE ON ONE guide
          sessions with one of our special education experts who understand the
          unique needs of your child.
        </article>
      </section>

      <section>
        <div className="franchiseForm-title">
          Navigating the World of{" "}
          <span className="yellow-color">Therapists</span> and Special Educators
          : <wbr />A Compassionate <span className="yellow-color">Guide</span>{" "}
          for Parents
        </div>
        <article className="franchiseForm-article">
          <br />
          Parents of special needs children often face the challenge of
          determining the right therapists and special educators for their
          child's unique requirements. At HomeShiksha, we understand this
          journey and offer guidance to help you make informed decisions that
          positively impact your child's development.
          <br />
          <br />
          Discover the right therapists and special educators with HomeShiksha.
          We're here to ensure that every step in your child's development is a
          step in the right direction.
          <br />
          <br />
          Engage in counseling sessions with our experts special educator who
          can provide guidance on the type of therapy or special education your
          child may benefit from. Our one on one sessions are tailored to your
          concerns and designed to support you in making well-informed decisions
          for your child's well-being.
          <br />
          <br />
          Navigate the path to finding the right professionals for your special
          needs child with the compassionate guidance of HomeShiksha.
        </article>
      </section>

      <section>
        <div className="franchiseForm-title">
          Charting the <span className="yellow-color">Right</span> Course After
          12th : <wbr />
          Your Gateway to <span className="yellow-color">Informed</span> Career
          Decisions
        </div>

        <article className="franchiseForm-article">
          For parents and students facing the pivotal decision of choosing a
          path after 12th standard in India, HomeShiksha is your go-to resource
          for comprehensive guidance. HomeShiksha offers specialized sessions
          and counseling to ensure you make informed decisions aligned with your
          interests and future aspirations.
          <br />
          <br />
          Embark on a journey of self-discovery with HomeShiksha. Our career
          exploration sessions and expert counseling ensure that your post-12th
          decisions align with your passions and lead to a future you'll thrive
          in. Let's sculpt success together.
          <ul>
            <br />
            <li>
              Tailored sessions for parents and students facing
              post-12thchoices.
            </li>
            <li>
              Ensuring well-informed decisions aligned with interests and
              aspirations.
            </li>
            <li>
              Expert counseling for a self-discovery journey after 12th
              standard.
            </li>
            <li>
              Choosing post-12th courses, offering insights and support in
              diverse academic, professional, and vocational options."
            </li>
          </ul>
          <br />
          College and Course Reviews : <wbr />
          Access detailed reviews of colleges and courses to make informed
          decisions about your educational future. Our website provides a wealth
          of information, ensuring that you choose the right path for your
          academic and professional aspirations.
        </article>
      </section>

      <section>
        <div className="franchiseForm-title">
          Choosing the <span className="yellow-color">Right</span> Stream After
          10th : <wbr />
          Your Stream to <span className="yellow-color">Prosperity</span>
        </div>
        <article className="franchiseForm-article">
          At HomeShiksha, we turn choices into opportunities. Our one on one
          guide sessions and counseling are your compass in the journey after
          10th, ensuring you choose a stream that aligns with your passions and
          leads to a future you'll thrive in. Illuminate the path to success
          with us.
          <ul>
            <br />
            <li>Tailored one-on-one guide sessions for focused attention. </li>
            <li>
              Expert support in navigating choices after 10th, like Science,
              Commerce, Arts, Vocational.
            </li>
            <li>
              Ensure the chosen stream aligns with the student's passions.
            </li>
            <li>
              Guidance for selecting a stream leading to a thriving future.
            </li>
          </ul>
          <br />
          Embarking on the journey after 10th is a pivotal moment. At
          HomeShiksha, we understand the importance of selecting the right
          stream aligned with your interests and aspirations. One on one guide
          sessions and counseling services are designed to illuminate this path
          for parents and students alike.
        </article>
      </section>

      <section>
        <div className="franchiseForm-title">
          Unlocking <span className="yellow-color">Bright</span> Futures :{" "}
          <wbr />
          Your Guide to <span className="yellow-color">Thriving</span> Careers
          after Graduation
        </div>
        <article className="franchiseForm-article">
          Start your journey of vocational counseling with us. If you're seeking
          a job post-graduation, our counseling goes beyond, helping you find a
          job that aligns with your interests and suits your desired industry.
          It's not just about finding any work - it's about finding the right
          work for you!
          <ul>
            <br />
            <li>
              Tailored sessions to understand your preferences, skills, and
              industry interests.
            </li>
            <li>
              Expert guidance to match your profile with jobs that align with
              your aspirations.
            </li>
            <li>
              Gain valuable insights into various industries and job
              opportunities.
            </li>
            <li>
              Receive recommendations for enhancing skills to excel in your
              chosen field.
            </li>
            <li>
              Assistance in navigating diverse career paths for a successful
              professional journey.
            </li>
          </ul>
          <br />
          If a person is looking for a job after graduation,This counseling is
          like career counseling, but it helps you find the best job that
          matches what you like and fits the industry you want to work in. Start
          your exciting career paths with our vocational counseling and step
          confidently into a bright future!
        </article>
      </section>

      <section>
        <div className="franchiseForm-title">
          Screen Smart, Live <span className="yellow-color">Bright</span> :{" "}
          <wbr />
          Our Guide to <span className="yellow-color">Tech</span> Harmony
        </div>
        <article className="franchiseForm-article">
          Homeshiksha's caring counselors make your digital journey smooth! We
          offer personalized support in managing screen time effectively,
          teaching responsible internet use, and ensuring a healthy blend of
          online and offline activities. Your tech wellbeing is in good hands
          with us - let's navigate the digital world together!
          <ul>
            <br />
            <li>Personalized Screen Time Management. </li>
            <li>Responsible Internet Use Education. </li>
            <li>Healthy Online and Offline Balance. </li>
            <li>Customized plans to enhance your overall tech wellbeing. </li>
            <li>
              Navigate the digital world confidently with our expert guidance.
            </li>
          </ul>
          <br />
        </article>
      </section>

      <section>
        <div className="franchiseForm-title">
          Study <span className="yellow-color">abroad</span> Counseling
        </div>

        <article className="franchiseForm-article">
          Embarking on a global education journey? Choosing courses, colleges,
          and countries can be overwhelming. With numerous top universities
          offering quality education, the challenge lies in making the right
          choices. The fear of selecting an unsuitable subject can impact your
          future significantly.
          <ul>
            <br />
            <li>
              Clear guidance on exams like SAT, GRE, or IELTS for specific
              countries.
            </li>
            <li>
              Navigate overwhelming choices with insights into renowned
              universities worldwide.
            </li>
            <li>
              Fearlessly choose courses aligned with your interests and future
              goals.
            </li>
            <li>
              Expert advice on selecting the right country for your academic
              journey.
            </li>
            <li>
              Make informed choices that resonate with your interests and
              capabilities.
            </li>
          </ul>
          <br />
          Our expert guidance ensures you make informed decisions, aligning your
          interests and capabilities with the perfect course, college, and
          country. Your academic adventure starts with confidence - let
          Homeshiksha be your guide!
          <br />
          <br />
          Gain insights from our seasoned career counselors. Navigate industry
          trends and decipher job markets confidently. Don't leave your future
          to chance. Navigate the crucial decision of choosing the right stream
          after 10th with confidence, supported by our expertise and resources
          of HomeShiksha. Let's shape your academic journey towards success
          together.
        </article>
      </section>
    </main>
  );
}
