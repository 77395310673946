import * as React from "react";
import "./appliedTutors.css";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../axios";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "fullName",
    headerName: "Name",
    width: 160,
    valueGetter: (params) =>
      `${params.row.first_name || ""} ${params.row.last_name || ""}`,
  },
  { field: "gender", headerName: "Gender", width: 90 },
  { field: "phone", headerName: "Phone", width: 130 },
  //   { field: "email", headerName: "Email", width: 160 },
  //   { field: "address", headerName: "Address", width: 200 },
  { field: "distance", headerName: "Distance", width: 110 },
  { field: "is_paid", headerName: "Verified", width: 90 },
  { field: "subjects", headerName: "Subjects", width: 5000 },
  //   {
  //     field: "pic",
  //     headerName: "Pic",
  //     width: 160,
  //     valueGetter: (params) => `${params.row.profile_pic_link}`,
  //   },
];

export default function AppliedTutors() {
  const [studentDetails, setStudentDetails] = useState({});
  const [dealData, setDealData] = useState({});
  const [tutorsDetails, setTutorsDetails] = useState([]);
  const { dealId } = useParams();

  const customColumns = columns.map((column) => {
    if (column.field === "pic") {
      return {
        ...column,
        renderCell: (params) => {
          const { value } = params;
          return (
            <div>
              <img src={value} alt="img" width={50} />
            </div>
          );
        },
      };
    } else if (column.field === "is_paid") {
      return {
        ...column,
        renderCell: (params) => {
          const { value } = params;
          return <div>{value ? "Yes" : "No"}</div>;
        },
      };
    } else if (column.field === "subjects") {
      return {
        ...column,
        renderCell: (params) => {
          const { value } = params;
          return (
            <div>
              {value.split(",").map((course) => {
                if (dealData.student_requirments.includes(course))
                  return <b> {course} |</b>;
                return <i> {course} |</i>;
              })}
            </div>
          );
        },
      };
    } else if (column.field === "distance") {
      return {
        ...column,
        renderCell: (params) => {
          const { value } = params;
          return <div>{value} Kms</div>;
        },
      };
    }

    return column;
  });

  useEffect(() => {
    axios
      .get(`/v1/tutor/applied-tutors?dealId=${dealId}`)
      .then((res) => {
        if (res.data.is_success) {
          const data = res.data.data;
          setDealData(data.deal_data);
          setStudentDetails(data.StudentDetails);
          setTutorsDetails(data.TutorsDetails);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  return (
    <main className="applied-tutor-main">
      <div className="applied-dealings-details-wrapper">
        <div className="dealings-lables-title">Student Details</div>

        <label className="dealings-details-label">
          First Name :{" "}
          <span className="dealings-label-value">
            {studentDetails.first_name}
          </span>
        </label>

        <label className="dealings-details-label">
          Last Name :{" "}
          <span className="dealings-label-value">
            {studentDetails.last_name}
          </span>
        </label>

        <label className="dealings-details-label">
          Phone :{" "}
          <span className="dealings-label-value">{studentDetails.phone}</span>
        </label>

        <label className="dealings-details-label">
          Gender :{" "}
          <span className="dealings-label-value">{studentDetails.gender}</span>
        </label>

        {studentDetails.address?.split("<-->")[3] != "undefined" && (
          <label className="dealings-details-label">
            Address :{" "}
            <span className="dealings-label-value">
              {studentDetails.address?.split("<-->")[3]}
            </span>
          </label>
        )}

        <label className="dealings-details-label">
          E-mail :{" "}
          <span className="dealings-label-value">{studentDetails.email}</span>
        </label>

        <label className="dealings-details-label">
          Required Subjects :{" "}
          <span className="dealings-label-value">
            {dealData.student_requirments}
          </span>
        </label>

        <label className="dealings-details-label">
          Tutor Gender Preference :{" "}
          <span className="dealings-label-value">
            {dealData.tutor_gender_preference}
          </span>
        </label>

        <label className="dealings-details-label">
          How Many Days In A Week :{" "}
          <span className="dealings-label-value">
            {dealData.how_many_days_in_a_week}
          </span>
        </label>

        <label className="dealings-details-label">
          When You Like To Start :{" "}
          <span className="dealings-label-value">
            {dealData.when_you_like_to_start}
          </span>
        </label>

        <label className="dealings-details-label">
          City :{" "}
          <span className="dealings-label-value">{studentDetails.city}</span>
        </label>

        <label className="dealings-details-label">
          Tution Type :{" "}
          <span className="dealings-label-value">{dealData.tution_type}</span>
        </label>
      </div>

      <div className="applied-dealings-tutors-datagrid-wrapper">
        <DataGrid
          rows={tutorsDetails}
          columns={customColumns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 15]}
          disableRowSelectionOnClick
        />
      </div>
    </main>
  );
}
