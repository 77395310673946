export const EVENTS = {
    UNVERIFIED_TUTOR_JOB: {
      event_name: "UNVERIFIED_TUTOR_JOB",
      category: "HS-Tutor",
      action: "HS-Unverified tutor Job Applied",
      label: "HS-Unverified tutor Job Applied",
      value: 1,
    },
    VERIFIED_TUTOR_JOB: {
      event_name: "VERIFIED_TUTOR_JOB",
      category: "HS-Tutor",
      action: "HS-Verified tutor Job Applied",
      label: "HS-Verified tutor Job Applied",
      value: 1,
    },
    WHATSAPP_REGISTER: {
      event_name: "WHATSAPP_REGISTER",
      category: "HS-Tutor",
      action: "HS-Register-Whatsapp",
      label: "HS-Refister-Whatsapp",
      value: 1,
    },
    SMS_REGISTER: {
      event_name: "SMS_REGISTER",
      category: "HS-Tutor",
      action: "HS-Register-SMS",
      label: "HS-Refister-SMS",
      value: 1,
    },
    GIVEN_MOBILE_NO: {
      event_name: "GIVEN_MOBILE_NO",
      category: "HS-Tutor",
      action: "HS-Given Mobile No",
      label: "HS-Given Mobile No",
      value: 1,
    },
    HS_STEP_NO_1: {
      event_name: "HS_STEP_NO_1",
      category: "HS-Tutor",
      action: "HS-Step-NO-1",
      label: "HS-Step-NO-1",
      value: 1,
    },
    HS_STEP_NO_2: {
      event_name: "HS_STEP_NO_2",
      category: "HS-Tutor",
      action: "HS-Step-NO-2",
      label: "HS-Step-NO-2",
      value: 1,
    },
    HS_STEP_NO_3: {
      event_name: "HS_STEP_NO_3",
      category: "HS-Tutor",
      action: "HS-Step-NO-3",
      label: "HS-Step-NO-3",
      value: 1,
    },
    HS_STEP_NO_4: {
      event_name: "HS_STEP_NO_4",
      category: "HS-Tutor",
      action: "HS-Step-NO-4",
      label: "HS-Step-NO-4",
      value: 1,
    },
    FAQ_CLICKED: {
      event_name: "FAQ_CLICKED",
      category: "HS-Tutor",
      action: "HS-FAQ-Clicked",
      label: "HS-FAQ-Clicked",
      value: 1,
    },
    WHATSAPP_LOGIN: {
      event_name: "WHATSAPP_LOGIN",
      category: "HS-Tutor",
      action: "HS-Login-Whatsapp",
      label: "HS-Login-Whatsapp",
      value: 1,
    },
    SMS_LOGIN: {
      event_name: "SMS_LOGIN",
      category: "HS-Tutor",
      action: "HS-Login-SMS",
      label: "HS-Login-SMS",
      value: 1,
    },
    SUPPORT_CLICKED: {
      event_name: "SUPPORT_CLICKED",
      category: "HS-Tutor",
      action: "HS-Support-Clicked",
      label: "HS-Support-Clicked",
      value: 1,
    },
    DOC_UPLOAD: {
      event_name: "DOC_UPLOAD",
      category: "HS-Tutor",
      action: "HS-Doc-Upload",
      label: "HS-Doc-Upload",
      value: 1,
    },
    PAY_NOW_BUTTON: {
      event_name: "PAY_NOW_BUTTON",
      category: "HS-Tutor",
      action: "HS-Pay Now Button",
      label: "HS-Pay Now Button",
      value: 1,
    },
    VERIFY_NOW_BUTTON: {
      event_name: "VERIFY_NOW_BUTTON",
      category: "HS-Tutor",
      action: "HS-Verify Now Button",
      label: "HS-Verify Now Button",
      value: 1,
    },
    VERIFY_NOW_BUTTON_2: {
      event_name: "VERIFY_NOW_BUTTON_2",
      category: "HS-Tutor",
      action: "HS-Verify Now Button-2",
      label: "HS-Verify Now Button-2",
      value: 1,
    },
    MY_ADDRESS: {
      event_name: "MY_ADDRESS",
      category: "HS-Tutor",
      action: "HS-My-Address",
      label: "HS-My-Address",
      value: 1,
    },
    UPDATED_ADDRESS: {
      event_name: "UPDATED_ADDRESS",
      category: "HS-Tutor",
      action: "HS-Updated Address",
      label: "HS-Updated Address",
      value: 1,
    },
    UPDATED_Course: {
      event_name: "UPDATED_Course",
      category: "HS-Tutor",
      action: "HS-Updated Course",
      label: "HS-Updated Course",
      value: 1,
    },
    SAVE_COURSES: {
      event_name: "SAVE_COURSES",
      category: "HS-Tutor",
      action: "HS-Save Courses",
      label: "HS-Save Courses",
      value: 1,
    },
    ADD_COURSES: {
      event_name: "ADD_COURSES",
      category: "HS-Tutor",
      action: "HS-Add Courses",
      label: "HS-Add Courses",
      value: 1,
    },
    RESUME_UPLOAD: {
      event_name: "RESUME_UPLOAD",
      category: "HS-Tutor",
      action: "HS-Resume Upload",
      label: "HS-Resume Upload",
      value: 1,
    },
    CV_UPLOAD: {
      event_name: "CV_UPLOAD",
      category: "HS-Tutor",
      action: "HS-CV Upload",
      label: "HS-CV Upload",
      value: 1,
    },
    PROFILE_PIC_UPLOAD: {
      event_name: "PROFILE_PIC_UPLOAD",
      category: "HS-Tutor",
      action: "HS-Profile Pic Upload",
      label: "HS-Profile Pic Upload",
      value: 1,
    },
};
