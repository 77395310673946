import React, { useEffect } from 'react'
import Faq from "react-faq-component";
import { logEvent } from '../analytics';
import { EVENTS } from '../constants/ga-constants';
export default function Faqs() {

    const data = {
        // title: "FAQ (How it works)",
        
        rows: [
            {
                title: "How much will I make once I start tuitions?",
                content: `You could be making anything between Rs.4000 to Rs 40000. It all depends on what you can 
                teach and the tuition requirements of the Parents.`,
            },
            {
                title: "How many tuitions I can take? Is there any limit in taking tuitions? ",
                content:"You can take as many tuitions as you like. It all depends on your availability and you timings.  There is no limit from HomeShiksha side in providing you tuitions opportunities. ",
            },
            {
                title: "What is the difference between verified tutor and unverified tutor? ",
                content: `Unverified Tutor : Upon completion of the your details via form, a tutor becomes an 
                unverified tutor with HomeShiksha. <br>
                Verified Tutor: To become a verified tutor, a tutor must submit or upload their valid 
                Government ID proof and pay the verification fee of Rs. 589 (including GST) which is valif 
                for life time. `,
            },
            {
                title: "Can I pay the verification fee after I receive a requirement?  ",
                content: `Unfortunately, it's not possible as you are supposed to visit students home representing 
                HomeShiksha. Therefore, before proceeding, we need to ensure that the tutor is verified by 
                HomeShiksha.  `,
            },
            {
                title: "What is the policy between HomeShiksha and tutors? ",
                content: `HomeShiksha takes a certain percentage of the tutor's first month tuition fee, from 2nd month 
                the tutor will receive 100% of their tuition fee directly. `,
            },
            {
                title: "Does HomeShiksha provide online tuition or home tuition? ",
                content: `HomeShiksha exclusively offers home tuition, where tutors are required to go to the students 
                houses to teach. `,
            },
            {
                title: "After how much time, I will get tuition opportunity once I become a verified tutor?",
                content: `We get day to day tuition requirements on daily basis. You can grab any tuition opportunity 
                as per you subjects you can teach, timing and distance.  You can also grab spot tuition offers 
                which matches your profile.`,
            },
            {
                title: " Can I obtain the requirements locally so that I don't have to travel anywhere?",
                content: `You are required to travel to a nearby location within a radius of 5 kilometres. We do our best 
                to provide you with the best tuition opportunity which suits your distance, timing, and your 
                flexibility. `,
            },
            
        ],
      };
      
      const styles = {
        bgColor: 'white',
        titleTextColor: "Black",
        rowTitleColor: "Black",
        rowContentColor: 'grey',
        arrowColor: "black",
      };
      
      const config = {
        animate: true,
        // arrowIcon: "V",
        tabFocus: true,
        expandIcon: "+",
        collapseIcon: "-",
      };
      useEffect(()=>{
        logEvent(EVENTS.FAQ_CLICKED.event_name);
      },[])
  return (
    <main className='main'>
        <section className="small-section" style={{margin:"0"}}>
                      <div className="small-blue-border" style={{backgroundColor:"rgba(242, 192, 12, 1)"}}></div>
                      <div className="small-section--text">
                        <div className="small-section--text-left">
                          <div className="small-section--text-left--title">Frequent <span style={{color:"rgba(242, 192, 12, 1)"}}>asked</span> questions </div>
                          {/* <div>is simply dummy text of the printing and typesetting industry.</div> */}
                        </div>
                        {/* <div className="small-section--text-right">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's is simply dummy text of the printing and typesetting industry</div> */}
                      </div>
      </section>
        <section>
              <Faq
                data={data}
                styles={styles}
                config={config}
            />
      </section>
    </main>
  )
}
