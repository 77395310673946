import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { personalData } from "../../states/personalData";
import axios from "../../axios";
import imageCompression from "browser-image-compression";
import { dashboardCardsData } from "../../states/dashboardCardsData";
import { logEvent } from "../../analytics";
import { EVENTS } from "../../constants/ga-constants";

export default function PersonalInfo() {
  const [editRequest, setEditRequest] = useState(false);
  const [userDetails, setUserDetails] = useAtom(personalData);
  const [loading, setLoading] = useState(false);
  const [profilePicLoad, setProfilePicLoad] = useState(false);
  const [cvLoad, setcvLoad] = useState(false);
  const [resumeLoad, setresumeLoad] = useState(false);
  const [showCvDoc, setShowCvDoc] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [cardsCenteralData, setCardsCenteralData] = useAtom(dashboardCardsData);

  const getPersonalDetails = () => {
    axios
      .get("/v1/user")
      .then((response) => {
        if (response.data.is_success) {
          const data = { ...response.data.data };
          let updatedData = {
            id: data.id,
            name: data.first_name,
            l_name: data.last_name,
            gender: data.gender,
            email: data.email,
            phone: data.phone,
            phone2: data.phone2 === "null" ? "" : data.phone2,
            latitude: data.latitude,
            longitude: data.longitude,
            flat: data.flat === "undefined" ? "" : data.flat,
            apartment: data.apartment === "undefined" ? "" : data.apartment,
            street: data.street === "undefined" ? "" : data.street,
            pincode: data.zip,
            city: data.city_name,
            state: data.state_name,
            // tutor_verified:data.is_active,
            profile_pic_link: data.profile_pic_link
              ? data.profile_pic_link
              : "./person-icon.png",
            user_type: data.user_type,
          };
          setUserDetails((pre) => ({ ...pre, ...updatedData }));
        } else {
          throw response.data.error;
        }
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const updateUserDetails = (_newData) => {
    axios
      .post(`v1/updateUserDetails`, {
        id: _newData.id,
        first_name: _newData.name,
        last_name: _newData.l_name,
        phone: _newData.phone,
        phone2: _newData.phone2,
        email: _newData.email,
        flat: _newData.flat,
        apartment: _newData.apartment,
        street: _newData.street,
        city_name: _newData.city,
        state_name: _newData.state,
        zip: _newData.pincode,
        latitude: _newData.latitude,
        longitude: _newData.longitude,
        user_type: "tutor",
      })
      .then((data) => {
        getPersonalDetails();
        return axios.get("/v1/tutor/dashboard-cards");
      })
      .then(async (response) => {
        setCardsCenteralData(response.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getVerificationStatusData = () => {
    axios
      .post(`v1/tutor/tutor-doc-status`)
      .then((res) => {
        const { adhaar, id_card, cv_file, resume_file, intro_video } =
          res.data.status;
        setUserDetails((pre) => {
          return { ...pre, adhaar, id_card, cv_file, resume_file, intro_video };
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const uploadDoc = (e, docType) => {
    try {
      const uploadedFile = e.target.files[0];
      if(!".pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif".includes(uploadedFile.name.split('.').slice(-1)[0])){
        alert("only .pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif format files are allowed")
        return
      }
      if (uploadedFile && uploadedFile.size <= 10 * 1024 * 1024) {
        switch (docType) {
          case "resume_file":
            setresumeLoad(true);
            logEvent(EVENTS.RESUME_UPLOAD.event_name);
            break;
          case "cv_file":
            setcvLoad(true);
            logEvent(EVENTS.CV_UPLOAD.event_name);
            break;
          default:
        }
        const formData = new FormData();
        formData.append("myImage", uploadedFile);
        axios
          .post(`v1/tutor/tutor-doc-upload`, formData, {
            headers: {
              "content-type": "multipart/form-data",
              "doc-type": docType,
            },
          })
          .then((res) => {
            getVerificationStatusData();
            switch (docType) {
              case "resume_file":
                setresumeLoad(false);
                break;
              case "cv_file":
                setcvLoad(false);
                break;
              default:
            }
          })
          .catch((error) => {
            switch (docType) {
              case "resume_file":
                setresumeLoad(false);
                break;
              case "cv_file":
                setcvLoad(false);
                break;
              default:
            }
            alert("Error While Uploading Document");
          });
      } else {
        alert("Document size should be less than or equal to 10 MB");
      }
    } catch (error) {
      alert("Error While Uploading Document");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((pre) => ({
      ...pre,
      [name]: value.replace('"', "'"),
    }));
  };

  const uploadedProfilePic = (e) => {
    try {
      let uploadedFile = e.target.files[0];
      if (uploadedFile && uploadedFile.size <= 5 * 1024 * 1024) {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920,
        };

        imageCompression(e.target.files[0], options)
          .then((response) => {
            uploadedFile = new File([response], e.target.files[0].name, {
              lastModified: e.target.files[0].lastModified,
              type: e.target.files[0].type,
            });

            setProfilePicLoad(true);
            const formData = new FormData();

            formData.append("myImage", uploadedFile);
            return axios.post(`v1/profilePic`, formData, {
              headers: { "content-type": "multipart/form-data" },
            });
          })
          .then((response) => {
            setProfilePicLoad(false);
            getVerificationStatusData();
            logEvent(EVENTS.PROFILE_PIC_UPLOAD.event_name);
          })
          .catch((error) => {
            setProfilePicLoad(false);
            alert("Error While Uploading Profile Pic");
          });
      } else {
        alert("Image size should be less than or equal to 5MB");
      }
    } catch (error) {
      alert("Error While Uploading Profile Pic");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getPersonalDetails();
    }, 1000);
  }, [profilePicLoad]);

  useEffect(() => {
    if (Object.keys(userDetails).length === 0) {
      getPersonalDetails();
      getVerificationStatusData();
    } else setLoading(false);
  }, []);

  const onInputClick = (event) => {
    event.target.value = null;
  };

  return !loading ? (
    <div className="personal-info--personal-content--wrapper">
      <div className="personal-info--personal-content-1">
        <div className="personal-info--personal-content-1-left">
          <div className="personal-info--personal-content-profilePic-wrapper">
            <div className="tutor-profilePic--wrapper">
              <img
                src={
                  userDetails.profile_pic_link != "null"
                    ? userDetails.profile_pic_link
                    : "./person-icon.png"
                }
                alt="user profile pic"
                width={window.innerWidth <= 768 ? "60" : "100"}
              />
            </div>
            <div className="showRequest--Edit-btn">
              {userDetails.phone && !profilePicLoad ? (
                <form>
                  <label htmlFor="profilePic-1">File Upload</label>
                  <input
                    id="profilePic-1"
                    type="file"
                    name="myImage"
                    accept=".jpg, .jpeg, .png, .gif"
                    onChange={uploadedProfilePic}
                    onClick={onInputClick}
                    style={{ display: "none" }}
                  />
                </form>
              ) : (
                <form>
                  <label>Uploading</label>
                </form>
              )}
            </div>
          </div>
          <div className="personal-info--personal-content-profile-name">
            {`Hi, ${userDetails.name}`}
          </div>
        </div>
        <div className="personal-info--personal-content-1-right">
          {!editRequest ? (
            <div
              onClick={() => {
                setEditRequest(() => true);
              }}
              style={{ cursor: "pointer", width: "50px", margin: "0 0 0 auto" }}
            >
              <span className="edit-txt">Edit</span>
              <img
                src="/pencil-icon.svg"
                alt="pencil-icon"
                width="15"
                height="15"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className={`personal-info--personal-content-2 ${
          editRequest ? "editInput" : ""
        }`}
      >
        <div className="personal-info--personal-content--field-wrapper">
          <div className="personal-info--personal-content-subtitle">
            <img
              src="/user-profile-focus.svg"
              alt="user-profile-focus"
              width="15"
              height="15"
            />
            First Name
          </div>
          <div className="personal-info--personal-content-data">
            <input
              type="text"
              readOnly={!editRequest}
              name="name"
              onChange={handleInputChange}
              value={userDetails.name}
            />
          </div>
          <div className="personal-info--personal-content-subtitle">
            <img
              src="/user-profile-focus.svg"
              alt="user-profile-focus"
              width="15"
              height="15"
            />
            Last Name
          </div>

          <div className="personal-info--personal-content-data">
            <input
              type="text"
              readOnly={!editRequest}
              name="l_name"
              onChange={handleInputChange}
              value={userDetails.l_name}
            />
          </div>
        </div>
        <div className="personal-info--personal-content--field-wrapper">
          <div className="personal-info--personal-content-subtitle">
            <img
              src="/user-profile-focus.svg"
              alt="user-profile-focus"
              width="15"
              height="15"
            />
            Mobile No
          </div>
          <div className="personal-info--personal-content-data">
            <input
              type="text"
              readOnly={editRequest}
              name="phone"
              // onChange={handleInputChange}
              value={userDetails.phone}
            />
          </div>
        </div>
        <div className="personal-info--personal-content--field-wrapper">
          <div className="personal-info--personal-content-subtitle">
            <img
              src="/user-profile-focus.svg"
              alt="user-profile-focus"
              width="15"
              height="15"
            />
            Alter Mobile No
          </div>
          <div className="personal-info--personal-content-data">
            <input
              type="text"
              readOnly={!editRequest}
              name="phone2"
              onChange={handleInputChange}
              value={userDetails.phone2}
            />
          </div>
        </div>
        <div className="personal-info--personal-content--field-wrapper">
          <div className="personal-info--personal-content-subtitle">
            <img
              src="/user-profile-focus.svg"
              alt="user-profile-focus"
              width="15"
              height="15"
            />
            Email Id{" "}
          </div>
          <div className="personal-info--personal-content-data">
            <input
              type="text"
              readOnly={!editRequest}
              name="email"
              onChange={handleInputChange}
              value={userDetails.email}
            />
          </div>
        </div>

        {/* <div className='personal-info--personal-content--field-wrapper'>
                      <form >
                        <div className='personal-info--personal-content-subtitle'>
                          <img src="/user-profile-focus.svg" alt="user-profile-focus" width="15" height="15"/>
                          Adhaar 
                        </div>
                        <div className='personal-info--personal-content-data'>

                          <label htmlFor='adhaar-1'><div className='step1-form-input-upload'><img src='./paperclip-icon.svg' alt="paperclip-icon"/>
                            {(userDetails.adhaar)?'Update':'Upload'}
                          </div></label>
                          <input id='adhaar-1' type="file" name="myImage" 
                          onChange={(e)=>uploadDoc(e,'adhaar')}
                          style={{display:"none"}} />
                        </div>
                      </form>
                    </div> */}

        <div className="personal-info--personal-content--field-wrapper">
          {userDetails.phone && (
            <form>
              <div className="personal-info--personal-content-subtitle">
                <img
                  src="/user-profile-focus.svg"
                  alt="user-profile-focus"
                  width="15"
                  height="15"
                />
                CV
              </div>
              <div className="personal-info--personal-content-data">
                {!cvLoad ? (
                  <>
                    <label htmlFor="cv_file-1">
                      <div
                        className={
                          userDetails.cv_file
                            ? "step1-form-input-update"
                            : "step1-form-input-upload"
                        }
                      >
                        <img src="./paperclip-icon.svg" alt="paperclip-icon" />
                        {userDetails.cv_file ? "Update" : "Upload"}
                      </div>
                    </label>
                    {userDetails.cv_file && (
                      <span
                        className="step1-form-input-upload"
                        onClick={() => {
                          setShowCvDoc(true);
                        }}
                      >
                        View
                      </span>
                    )}
                  </>
                ) : (
                  <label>
                    <div className={"step1-form-input-update"}>Uploading</div>
                  </label>
                )}
                <dialog open={showCvDoc} className="tc-dialog-box">
                  {userDetails.cv_file &&
                  ".jpg,.jpeg,.png,.gif,.mp4,.mov,.avi".includes(
                    userDetails.cv_file.split(".").pop().toLowerCase()
                  ) ? (
                    <img
                      src={userDetails.cv_file}
                      alt="cv file "
                      width={"100%"}
                      height={"100%"}
                    />
                  ) : (
                    <>
                      <a
                        href={userDetails.cv_file}
                        target="_blank"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "20px",
                        }}
                        rel="noopener noreferrer"
                      >
                        Download CV
                      </a>
                    </>
                  )}
                  <button
                    onClick={() => {
                      setShowCvDoc(false);
                    }}
                    type="button"
                  >
                    Close
                  </button>
                </dialog>

                <input
                  id="cv_file-1"
                  type="file"
                  name="myImage"
                  onClick={onInputClick}
                  accept=".pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif"
                  onChange={(e) => uploadDoc(e, "cv_file")}
                  style={{ display: "none" }}
                />
              </div>
            </form>
          )}
        </div>

        <div className="personal-info--personal-content--field-wrapper">
          {userDetails.phone && (
            <form>
              <div className="personal-info--personal-content-subtitle">
                <img
                  src="/user-profile-focus.svg"
                  alt="user-profile-focus"
                  width="15"
                  height="15"
                />
                Resume
              </div>
              <div className="personal-info--personal-content-data">
                {!resumeLoad ? (
                  <>
                    <label htmlFor="resume_file-1">
                      <div
                        className={
                          userDetails.resume_file
                            ? "step1-form-input-update"
                            : "step1-form-input-upload"
                        }
                      >
                        <img src="./paperclip-icon.svg" alt="paperclip-icon" />
                        {userDetails.resume_file ? "Update" : "Upload"}
                      </div>
                    </label>
                    {userDetails.resume_file && (
                      <span
                        className="step1-form-input-upload"
                        onClick={() => {
                          setShowResume(true);
                        }}
                      >
                        View
                      </span>
                    )}
                  </>
                ) : (
                  <label>
                    <div className={"step1-form-input-update"}>Uploading</div>
                  </label>
                )}

                <dialog open={showResume} className="tc-dialog-box">
                  {userDetails.resume_file &&
                  ".jpg,.jpeg,.png,.gif,.mp4,.mov,.avi".includes(
                    userDetails.resume_file.split(".").pop().toLowerCase()
                  ) ? (
                    <img
                      src={userDetails.resume_file}
                      alt="resume_file "
                      width={"100%"}
                      height={"100%"}
                    />
                  ) : (
                    <>
                      <a
                        href={userDetails.resume_file}
                        target="_blank"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "20px",
                        }}
                        rel="noopener noreferrer"
                      >
                        Download Resume
                      </a>
                    </>
                  )}
                  <button
                    onClick={() => {
                      setShowResume(false);
                    }}
                    type="button"
                  >
                    Close
                  </button>
                </dialog>
                <input
                  id="resume_file-1"
                  type="file"
                  name="myImage"
                  onClick={onInputClick}
                  accept=".pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif"
                  onChange={(e) => uploadDoc(e, "resume_file")}
                  style={{ display: "none" }}
                />
              </div>
            </form>
          )}
        </div>

        {/* <div className='personal-info--personal-content--field-wrapper'>
                      {(userDetails.phone)&&<form >
                        <div className='personal-info--personal-content-subtitle'>
                          <img src="/user-profile-focus.svg" alt="user-profile-focus" width="15" height="15"/>
                          Intro video 
                        </div>
                        <div className='personal-info--personal-content-data'>

                          <label htmlFor='intro_video-1'><div className={(userDetails.intro_video)?'step1-form-input-update':'step1-form-input-upload'}><img src='./paperclip-icon.svg' alt="paperclip-icon"/>
                            {(userDetails.intro_video)?'Update':'Upload'}
                          </div></label>
                          <input id='intro_video-1' type="file" name="myImage" onClick={onInputClick} accept=".mp4, .mov, .avi"
                          onChange={(e)=>uploadDoc(e,'intro_video')}
                          style={{display:"none"}} />
                        </div>
                      </form>}
                    </div> */}

        {/* <div className='personal-info--personal-content--field-wrapper'>
                      <form >
                        <div className='personal-info--personal-content-subtitle'>
                          <img src="/user-profile-focus.svg" alt="user-profile-focus" width="15" height="15"/>
                          ID Card 
                        </div>
                        <div className='personal-info--personal-content-data'>

                          <label htmlFor='id_card-1'><div className='step1-form-input-upload'><img src='./paperclip-icon.svg' alt="paperclip-icon"/>
                            {(userDetails.id_card)?'Update':'Upload'}
                          </div></label>
                          <input id='id_card-1' type="file" name="myImage" onClick={onInputClick} 
                          onChange={(e)=>uploadDoc(e,'id_card')}
                          style={{display:"none"}} />
                        </div>
                      </form>
                    </div> */}
      </div>

      {editRequest && (
        <div className="showRequest-Save-cancel-wrapper">
          <div
            onClick={() => {
              // setPersonalInfo(()=>originalPersonalInfo)
              getPersonalDetails();
              setEditRequest(() => false);
            }}
            className="showRequest-Save-wrapper showRequest-cancel-wrapper"
          >
            <button>cancel</button>
          </div>
          <div
            onClick={() => {
              // setOriginalPersonalInfo(()=>userDetails)
              updateUserDetails(userDetails);
              setEditRequest(() => false);
            }}
            className="showRequest-Save-wrapper"
          >
            <button>Save</button>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div className="loading">
        <img src="./book-loader.gif" alt=" Loading . . .  " />
      </div>
    </div>
  );
}
