import { BrowserRouter as Router, Routes, Route,NavLink } from 'react-router-dom';
import Navigation from './components/navigation';
import VerifyAccount from './webpages/verify-account';
import Dashboard from './webpages/dashboard';
import LoginPage from './webpages/loginPage';
import MyProfile from './webpages/myProfile/myProfile'
import MyCourses from './webpages/myProfile/mycourses';
import PersonalInfo from './webpages/myProfile/personalInfo';
import Address from './webpages/myProfile/address';
import Support from './webpages/support';
import Student from './webpages/student';
import TutorRegister from './tutor-register';
import WaLogin from './webpages/waLogin';
import Faqs from './webpages/faq';
import Franchise from './webpages/franchiseForm';
import Counselling from './webpages/counselling';
import AppliedTutors from './webpages/appliedTutors';
import './App.css';
import { useEffect, useState } from 'react';
import { logPageView } from './analytics';
import ReactGA from "react-ga4";
function App() {

  const [currentPath,setCurrentPath]=useState()
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  useEffect(()=>{
    setCurrentPath(()=>window.location.pathname)
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title:"Home Page"
    });
  },[])

  return (
    <div>
      <Router>

        <header className='top-bar--wrapper'>

          <div className='top-bar-logo'>
              <NavLink to={process.env.REACT_APP_OLD_HOMESHIKSHA} onClick={()=>{setCurrentPath(()=>'/dashboard')}}><img src="/homeshiksha-logo.jpg" alt="logo" /></NavLink>
          </div>
          {(!['/', '/register', '/own-a-franchise','/counselling'].includes(currentPath)&&!currentPath?.includes("/wa-login/")&&!currentPath?.includes("/tutor-applied/"))&&<Navigation/>}
          {(currentPath==='/')&&<div><NavLink to='/register' onClick={()=>{setCurrentPath(()=>'/register')}} className='top-bar-btns'>Register</NavLink></div>}
          {['/register', '/own-a-franchise','/counselling'].includes(currentPath)&&<div><NavLink to='/'  onClick={()=>{setCurrentPath(()=>'/')}} className='top-bar-btns'>Login</NavLink></div>}
        </header>

        <Routes>
          <Route path='/' element={<LoginPage/>} />
          <Route path='/wa-login/:randomString' element={<WaLogin/>} />
          <Route path='/register' element={<TutorRegister/>} />
          <Route path='/my-courses' element={<MyCourses/>} />
          <Route path='/tutor-applied/:dealId' element={<AppliedTutors/>} />
          <Route path='/my-profile' element={<MyProfile/>} />
          <Route path='/faqs' element={<Faqs/>} />
          <Route path='/counselling' element={<Counselling/>} />
          <Route path='/own-a-franchise' element={<Franchise/>} />
          <Route path='/support' element={<Support/>} />
          <Route path='/student' element={<Student/>} />
          <Route path='/personal-info' element={<PersonalInfo/>} />
          <Route path='/address' element={<Address/>} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/verify-account' element={<VerifyAccount />} />

        </Routes>
      </Router>
     
    </div>
  );
}

export default App;
