
export const Mode_of_tution=[
    "Looking for Online Tutor",
    "Looking for Home Tutor",
    "Either Home/Online Tutor",
]
export const when_to_start=[
    "Immediately",  
    "In Few Days",  
    "In A Week",  
    "In 10 Days",  
    "In This Month",  
    "Next Month" 
]
export const curricular_course_level=[
    "Basic Level",
    "Intermediate Level",
    "Advanced Level",
]
export const intermediateLevels=[
    "Primary / Pre-Primary",
    "LKG / UKG",
    "1st Class",
    "2nd Class",
    "3rd Class",
    "4th Class",
    "5th Class",
    "6th Class",
    "7th Class",
    "8th Class",
    "9th Class",
    "10th Class",
    "11th Class",
    "12th Class",
]
export const academy_course_level=[
    "Primary / Pre-Primary",
    "LKG / UKG",
    "1st Class",
    "2nd Class",
    "3rd Class",
    "4th Class",
    "5th Class",
    "6th Class",
    "7th Class",
    "8th Class",
    "9th Class",
    "10th Class",
    "11th Class",
    "12th Class",
    // "Others" ,
    // "primary_subjects",
    // "secondary_subjects_upto_8",
    // "diploma",
    // "pre_primary",
    // "Intermediate_1",
    // "Bakaram",
    // "Intermediate_2",
    // "ALL"
]

export const Days_per_week=[
    "1 Day a week",
    "2 Days a week",
    "3 Days a week",
    "4 Days a week",
    "5 Days a week",
    "6 Days a week",
]

export const tutor_gender=["I need only Male Tutor",
"I need only Female Tutor",
"Either Male or Female "]

export const commonFormTemplate=[
{
    "inputType":"dropDown",
    "inputName":"tutor_gender",
    "title":"Require Male/Female Tutor ?",
    "options":tutor_gender,
    "defaultValue":"",
    "value":"Select Value",
},{
    "inputType":"dropDown",
    "inputName":"no_of_days_per_week_required",
    "title":"How many days in a week you need tuition?",
    "options":Days_per_week,
    "defaultValue":"",
    "value":"Select Value",
},{
    "inputType":"dropDown",
    "inputName":"when_to_start_class",
    "title":"When would you like to start tuition ?",
    "options":when_to_start,
    "defaultValue":"",
    "value":"Select Value",
},
{
    "inputType":"dropDown",
    "inputName":"mode_of_tution",
    "title":"Mode of Tuition ?",
    "options":Mode_of_tution,
    "defaultValue":"",
    "value":"Select Value",
},
{
    "inputType":"textBox",
    "inputName":"pricing",
    "title":"Pricing ?",
    "options":"",
    "defaultValue":"",
    "value":""
},
]
export const academyFormTemplate=[
    {
        "inputType":"dropDown",
        "inputName":"course_level",
        "title":"For Which Level you need a Tutor ?",
        "options":academy_course_level,
        "defaultValue":"",
        "value":"Select Value",
    },
    {
        "inputType":"dropDown",
        "inputName":"stream_board",
        "title":"Choose the school board?",
        "options":[],
        "defaultValue":"",
        "value":"Select Value",
    },
    {
        "inputType":"dropDown-select",
        "inputName":"course_name",
        "title":"For Which Course you need a Tutor?",
        "options":[],
        "defaultValue":"",
        "value":"",
    },


]

export const curricularFormTemplate=[
{
    "inputType":"dropDown",
    "inputName":"stream_board",
    "title":"Choose the Course Stream ?",
    "options":[],
    "defaultValue":"",
    "value":"Select Value",
    },
{
    "inputType":"dropDown-select",
    "inputName":"course_name",
    "title":"For Which Course you need a Tutor?",
    "options":[],
    "defaultValue":"",
    "value":"",
},
]

export const competitiveExamsFormTemplate=[
{
    "inputType":"dropDown",
    "inputName":"stream_board",
    "title":"Select the Exam board ",
    "options":[],
    "defaultValue":"",
    "value":"Select Value",
},
{
    "inputType":"dropDown-select",
    "inputName":"course_name",
    "title":"For Which Course you need a Tutor ?",
    "options":[],
    "defaultValue":"",
    "value":"",
},

]

export const intermediateFormTemplate=[
    {
        "inputType":"dropDown",
        "inputName":"course_level",
        "title":"For Which Level you need a Tutor ?",
        "options":intermediateLevels,
        "defaultValue":"",
        "value":"Select Value",
    },
    {
        "inputType":"dropDown",
        "inputName":"stream_board",
        "title":"Choose the school board?",
        "options":[],
        "defaultValue":"",
        "value":"Select Value",
    },
    {
        "inputType":"dropDown-select",
        "inputName":"course_name",
        "title":"For Which Course you need a Tutor?",
        "options":[],
        "defaultValue":"",
        "value":"",
    },
]

export const abroadExamsFormTemplate=[
{
    "inputType":"dropDown",
    "inputName":"stream_board",
    "title":"Choose the school board?",
    "options":[],
    "defaultValue":"",
    "value":"Select Value",
},
{
    "inputType":"dropDown-select",
    "inputName":"course_name",
    "title":"For Which Course you need a Tutor?",
    "options":[],
    "defaultValue":"",
    "value":"",
},
]

export const learnLanguageFormTemplate=[
{
    "inputType":"dropDown",
    "inputName":"stream_board",
    "title":"Choose the Language Type ?",
    "options":[],
    "defaultValue":"",
    "value":"Select Value",
},
{
    "inputType":"dropDown-select",
    "inputName":"course_name",
    "title":"For Which Course you need a Tutor?",
    "options":[],
    "defaultValue":"",
    "value":"",
},
]

export const iTCoursesFormTemplate=[
{
    "inputType":"dropDown",
    "inputName":"stream_board",
    "title":"Choose the school board?",
    "options":[],
    "defaultValue":"",
    "value":"Select Value",
},
{
    "inputType":"dropDown-select",
    "inputName":"course_name",
    "title":"For Which Course you need a Tutor?",
    "options":[],
    "defaultValue":"",
    "value":"",
},
]
export const specialKidsFormTemplate=[
{
    "inputType":"dropDown",
    "inputName":"stream_board",
    "title":"Choose the age Group ?",
    "options":[],
    "defaultValue":"",
    "value":"Select Value",
},
{
    "inputType":"dropDown-select",
    "inputName":"course_name",
    "title":"Select a service you are looking for",
    "options":[],
    "defaultValue":"",
    "value":"",
},
]

export const genderConversion={
    "I need only Male Tutor":"male",
    "I need only Female Tutor":"female",
    "Either Male or Female ":"any",
    "male":"I need only Male Tutor",
    "female":"I need only Female Tutor",
    "any":"Either Male or Female "
  }
  
export const modeOfTutionconversion={
    "Looking for Online Tutor":"online",
    "online":"Looking for Online Tutor",
    "Looking for Home Tutor":"offline",
    "offline":"Looking for Home Tutor",
    "Either Home/Online Tutor":"any",
    "any":"Either Home/Online Tutor",
}


export const citysList=[
            "Hyderabad",
            "Bangalore",
            "Mysore",
            "Pune",
            "Dehradun",
            "Raipur/Bhilai/Durg",
            "Chennai",
            "Vijayawada",
            "Mumbai",
            "Gurgaon",
            "Delhi",
            // "Other",
          ]