import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { dashboardCardsData } from "../states/dashboardCardsData";
import { personalData } from "../states/personalData";
import axios from "../axios";
import { useNavigate } from "react-router-dom";
import { alertData } from "../states/alert";
import { logEvent, logPageView } from "../analytics";
import { EVENTS } from "../constants/ga-constants";

export default function StudentCard({ cardId }) {
  const [cardsCenteralData, setCardsCenteralData] = useAtom(dashboardCardsData);
  const [personalCenteralData, setPersonalCenteralData] = useAtom(personalData);
  const [currentCard, setCurrentCard] = useState();
  const [alert, setAlert] = useAtom(alertData);

  const navigation = useNavigate();

  const updateCentralData = (newCentralData) => {
    let updatedCurrentData = { ...currentCard, ...newCentralData };
    setCurrentCard(() => updatedCurrentData);
  };

  const postAppliedJobs = () => {
    axios
      .post("/v1/tutor/applied-jobs", {
        dealings_id: currentCard.dealings_id,
        distance: currentCard.distance,
      })
      .then((response) => {
        if (response.data.is_success) {
          setCardsCenteralData((currentDate) => {
            const AppliedJobIds = response.data.data;
            const newDate = currentDate.map((card) => {
              if (AppliedJobIds.indexOf(parseInt(card.dealings_id)) !== -1)
                return { ...card, isActive: true };
              return card;
            });
            return newDate;
          });

          if (!personalCenteralData.is_paid) {
            logEvent(EVENTS.UNVERIFIED_TUTOR_JOB.event_name)
            navigation("/verify-account");
          } else logEvent(EVENTS.VERIFIED_TUTOR_JOB.event_name);
          setAlert(
            "Successfully Applied, Our CRE will reach out to you soon. "
          );
        }
      })
      .catch((err) => {
        // console.log("error while appling to job ");
      });
  };

  useEffect(() => {
    setCurrentCard(() => cardsCenteralData.find((card) => card.id === cardId));
  }, [cardId, cardsCenteralData]);

  return currentCard ? (
    <div
      className={`student-card-wrapper ${
        personalCenteralData.tutor_verified
          ? "student-card-wrapper-verified"
          : "student-card-wrapper-unverified"
      }`}
    >
      <div className="student-card-left-content-wrapper">
        <div className="space-between-flex">
          <div className="student-card-name">
            <img src="./card-person-icon.svg" alt="card-person-icon" />{" "}
            {currentCard.student_name}
          </div>
          <div className="student-card-right-content-1">
            <div className="student-card-status-blink"></div>
            <div className="student-card-status-title">
              {currentCard.status}
            </div>
            {/* {currentCard.amount && (
              <div className="student-card-amount">
                Rs.{currentCard.amount} /Month
              </div>
            )} */}
          </div>
        </div>
        <div className="student-card-subjects">
          <img src="./card-subject-icon.svg" alt="card-subject-icon" /> Subjects
          : {currentCard.subjects}
        </div>
        <div className="student-card-subjects">
          <img src="./card-subject-icon.svg" alt="card-address-icon" /> Level :{" "}
          {currentCard.level} , {currentCard.stream}
        </div>
        {/* <div className="student-card-subjects">
          <img src="./card-subject-icon.svg" alt="card-address-icon" /> Stream :{" "}
          {currentCard.stream}
        </div> */}
        <div className="student-card-subjects">
          <img src="./card-subject-icon.svg" alt="card-address-icon" />{" "}
          Classes/Week : {currentCard.days_per_week}
        </div>
        {currentCard.card_domain === "recommended" && (
          <div className="space-between-flex">
            <div className="student-card-subjects">
              <img src="./card-address-icon.svg" alt="card-address-icon" />{" "}
              Distance : {currentCard.distance.toFixed(1)} Km away
            </div>

            <div className="student-card-right-content-2">
              {currentCard.isActive ? (
                <div
                  className={`student-card-right-content-2--btn forms-next-btn ${
                    personalCenteralData.is_paid ? "yellow-applied-btn" : ""
                  }`}
                >
                  Applied
                </div>
              ) : (
                <div
                  className="student-card-right-content-2--btn forms-next-btn"
                  onClick={() => {
                    updateCentralData({ isActive: true });
                    postAppliedJobs();
                  }}
                >
                  Apply
                </div>
              )}
            </div>
          </div>
        )}
        {/* {currentCard.card_domain === "recommended" && (
          <div className="student-card-subjects">
            <img src="./card-address-icon.svg" alt="card-address-icon" />{" "}
            Distance : {currentCard.distance.toFixed(1)} Km away
          </div>
        )} */}

        {currentCard.address != "undefined" && currentCard.address && (
          <div className="student-card-subjects">
            <img src="./card-address-icon.svg" alt="card-address-icon" />{" "}
            Address :{" "}
            {currentCard.address
              .split(",")
              .map((val, ind, arr) => (ind < 3 ? val : ""))}
          </div>
        )}
        {(currentCard.address == "undefined" || !currentCard.address) && (
          <div className="student-card-subjects">
            <img src="./card-address-icon.svg" alt="card-address-icon" />{" "}
            Address {currentCard.zip}
          </div>
        )}
        {/* {currentCard.card_domain !== "myclasses" ? (
          currentCard.amount && (
            <div className="student-card-subjects">
              <img src="./card-money-icon.svg" alt="card-money-icon" /> Rs.{" "}
              {currentCard.amount}/-
            </div>
          )
        ) : (
          <div className="student-card-subjects">
            <img src="./card-timing-icon.svg" alt="card-timing-icon" />
            {currentCard.timing}
          </div>
        )} */}
        {/* <div className="student-card-right-content-2">
          {currentCard.isActive ? (
            <>
              <div className="student-card-right-content-2--status">
                waiting...
              </div>
              <div
                className={`student-card-right-content-2--btn forms-next-btn ${
                  personalCenteralData.is_paid ? "yellow-applied-btn" : ""
                }`}
              >
                Applied
              </div>
            </>
          ) : (
            <div
              className="student-card-right-content-2--btn forms-next-btn"
              onClick={() => {
                updateCentralData({ isActive: true });
                postAppliedJobs();
              }}
            >
              Apply
            </div>
          )}
        </div> */}
        {/* <div className="student-card-TC"> *Terms & Conditions</div> */}
      </div>

      {/* {currentCard.card_domain === "recommended" && (
        <div className="student-card-right-content-wrapper">
          <div className="student-card-right-content-1">
            <div className="student-card-status-blink"></div>
            <div className="student-card-status-title">
              {currentCard.status}
            </div>
            {currentCard.amount && (
              <div className="student-card-amount">
                Rs.{currentCard.amount} /Month
              </div>
            )}
          </div>

          <div className="student-card-right-content-2">
            {currentCard.isActive ? (
              <>
                <div className="student-card-right-content-2--status">
                  waiting...
                </div>
                <div
                  className={`student-card-right-content-2--btn forms-next-btn ${
                    personalCenteralData.is_paid ? "yellow-applied-btn" : ""
                  }`}
                >
                  Applied
                </div>
              </>
            ) : (
              <div
                className="student-card-right-content-2--btn forms-next-btn"
                onClick={() => {
                  updateCentralData({ isActive: true });
                  postAppliedJobs();
                }}
              >
                Apply
              </div>
            )}
          </div>
        </div>
      )} */}

      {currentCard.card_domain === "myclasses" && (
        <div className="student-card-right-content-wrapper">
          <div className="student-card-right-content-1">
            <div className="myclasses-call-to-action-icon">
              <a href={`tel:${currentCard.phone.slice(-10)}`}>
                <img
                  src="./call-to-action-icon.svg"
                  alt="call-to-action-icon"
                />
              </a>
            </div>
            <div style={{ color: "grey", fontWeight: "500" }}>
              {currentCard.phone}
            </div>
          </div>

          {/* <div className='student-card-right-content-2'>
                {(currentCard.isActive)
                ?<>
                  <div className='student-card-right-content-2--status'>Processing...</div>
                  <div className='student-card-right-content-2--btn forms-next-btn'>Stoping</div>
                </>
                :
                  <div className='student-card-right-content-2--btn forms-next-btn' onClick={()=>{updateCentralData({isActive:true})}}>Stop</div>
                }
            </div> */}
        </div>
      )}
      {currentCard.card_domain === "mydemo" && (
        <div className="student-card-right-content-wrapper">
          <div className="student-card-right-content-1">
            <div className="myclasses-call-to-action-icon">
              <a href={`tel:${currentCard.phone.slice(-10)}`}>
                <img
                  src="./call-to-action-icon.svg"
                  alt="call-to-action-icon"
                />
              </a>
            </div>
            <div style={{ color: "grey", fontWeight: "500" }}>
              {currentCard.phone}
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}
