import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../axios";
import { logEvent, logPageView } from "../analytics";

import "./loginPage.css";
import { EVENTS } from "../constants/ga-constants";

const LoginPage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [loader, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [smsOtpVerification, setSmsOtpVerification] = useState(false);
  const [verificationProcess, setVerificationProcess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showOtpError, setShowOtpError] = useState("");
  const [otpCountDown, setOtpCountDown] = useState(120);
  const [showResendOtp, setShowResendOtp] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    logPageView("Login Page");

    let jwt = JSON.parse(localStorage.getItem("homeshiksha-tutor-jwt"));
    if (!jwt) {
      localStorage.setItem("userDetails", JSON.stringify({}));
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/dashboard");
      window.location.reload();
    }
  }, []);

  const genetateOTP = (phoneNumber, login_method = "sms") => {
    axios
      .post(`v1/generateOtp`, {
        phoneNo: phoneNumber,
        login_method,
        user_type: "Tutor",
      })
      .then((data) => {
        data = data.data;
        if (!data.is_success) {
          setShowOtpError(() => data.error.msg);
          return;
        }
        let otp = data.data.otp;

        setOtp(otp);
        // setGeneratedOtp(true)
        if (login_method === "whatsapp") {
          // if otp is null or empty then it is sms otp
          const whatsappLink = `https://wa.me/917386936695?text=Log%20me%20in%20to%20Homeshiksha%20%3A%20H-${otp}`;
          setTimeout(() => {
            window.open(whatsappLink, "_blank");
          });
          checkForJWT("whatsapp", otp);
        }
      })
      .catch((err) => {
        // // console.log(err);
        setShowError(true);
        setTimeout(() => {
          navigate("/register");
        }, 2000);
      });
  };

  const checkForJWT = (login_method, otp = "") => {
    let findTokenInterval = setInterval(() => {
      getJWT(findTokenInterval, login_method, otp);
    }, 3000);
    setTimeout(() => {
      clearInterval(findTokenInterval);
      setVerificationProcess(false);
    }, 2 * 60 * 1000);
  };

  const getJWT = async (tokenInterval, login_method, otp) => {
    // console.log("finding token ...",tokenInterval);
    try {
      // // console.log({phoneNo:phoneNumber,otp:otp,login_method});
      let data = await axios.post(`v1/generateJWT`, {
        phoneNo: phoneNumber,
        otp: otp,
        login_method,
      });
      if (!data.data.is_success) return;
      localStorage.setItem(
        "homeshiksha-tutor-jwt",
        JSON.stringify(data.data.data.token)
      );
      clearInterval(tokenInterval);
      setVerificationProcess(false);
      navigate("/dashboard");
      window.location.reload();
    } catch (error) {
    }
  };

  const handleWhatsAppLogin = () => {
    if (phoneNumber.length < 10) {
      setShowWarning(true);
      return;
    } else {
      setVerificationProcess(true);
      setShowWarning(false);
      genetateOTP(phoneNumber, "whatsapp");
      logEvent(EVENTS.WHATSAPP_LOGIN.event_name);
    }
  };

  const handlePhoneLogin = () => {
    if (phoneNumber.length < 10) {
      setShowWarning(true);
      return;
    } else {
      logEvent(EVENTS.SMS_LOGIN.event_name);
      setShowResendOtp(() => false);
      setOtpCountDown(120);
      setShowWarning(false);
      setSmsOtpVerification(() => true);
      genetateOTP(phoneNumber, "sms");
      let stopCountDown = setInterval(() => {
        setOtpCountDown((pre) => {
          if (pre === 0) {
            clearInterval(stopCountDown);
            setShowResendOtp(() => true);
            return 120;
          }
          return pre - 1;
        });
      }, 1000);
      
    }
  };

  const handleOtpChange = (e) => {
    let otp = e.target.value;
    setOtp(() => otp);
    if (otp.length == 6) {
      setLoading(() => true);
      axios
        .post(`v1/verifyOtp`, { phoneNo: phoneNumber, otp: otp })
        .then((data) => {
          checkForJWT("sms", otp);
        })
        .catch((err) => {});
    } else {
      setLoading(() => false);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const phoneValue = e.target.value.replace(/\D/g, "");
    if (phoneValue.length > 0) setLoading(true);
    if (phoneValue.length < 1) setLoading(false);
    setPhoneNumber(phoneValue);
    if (phoneValue.length == 10) {
      setLoading(false);
      setShowWarning(false);
      logEvent(EVENTS?.GIVEN_MOBILE_NO?.event_name);
    } else {
      // setGeneratedOtp(false)
      setShowError(false);
    }
  };

  const formatSeconds = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min} min ${sec} sec`;
  };

  return (
    <main className="main login--wrapper">
      <div
        style={{
          color: "rgba(24, 24, 24,0.5)",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        Bring confidence with learning
      </div>
      <img src="/loginPage-pic.svg" height={"240vh"} alt="pencil icon" />
      {!smsOtpVerification && (
        <>
          <h2 style={{ color: "rgba(24, 24, 24, 0.49)", fontSize: "16px" }}>
            Enter Phone Number
          </h2>
          {showWarning && (
            <div className="warningAlert">Please enter at least 10 digits.</div>
          )}
          {showError && (
            <div className="errorAlert">
              User Not Found , Redirecting to Registeration{" "}
            </div>
          )}
          {showOtpError && <div className="errorAlert">{showOtpError} </div>}
          <div className="input-loading-wrapper">
            <input
              type="tel"
              placeholder="Enter your phone number"
              className="loginInputField"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              style={{ textAlign: "center" }}
            />
            <div className="genetating-otp-loader">
              {/* {(generatedOtp)&&<img src="/bigtick-icon.svg" alt="pencil icon"/>} */}
            </div>
          </div>

          <div className="login-btns-wrapper">
            <div id="whatsapp-login-btn">
              <button
                type="button"
                className="loginPrimaryButton"
                onClick={handleWhatsAppLogin}
              >
                Login via Whatsapp
              </button>
              <img
                src="./book-loader.gif"
                alt=" Loading . . .  "
                className={`${verificationProcess ? "" : "in"}active-img`}
              />
            </div>
            <div>
              <button
                type="button"
                className="loginPrimaryButton"
                onClick={handlePhoneLogin}
              >
                Login via Phone
              </button>
              <img
                src="./book-loader.gif"
                alt=" Loading . . .  "
                className={`inactive-img`}
              />
            </div>
          </div>
        </>
      )}

      {smsOtpVerification && (
        <>
          <h2 style={{ color: "rgba(24, 24, 24, 0.49)", fontSize: "16px" }}>
            OTP is Sent to {phoneNumber}
          </h2>
          {showOtpError && <div className="errorAlert">{showOtpError} </div>}
          <div className="input-loading-wrapper">
            <input
              type="tel"
              placeholder="Enter your OTP"
              className="loginInputField"
              value={otp}
              onChange={(e) => {
                handleOtpChange(e);
              }}
              style={{ textAlign: "center" }}
            />
            <div className="genetating-otp-loader">
              {loader && <img src="./book-loader.gif" alt=" Loading . . .  " />}
            </div>
          </div>
          <div className="login-btns-wrapper">
            <div>
              {showResendOtp ? (
                <button
                  type="button"
                  className="loginPrimaryButton"
                  onClick={() => {
                    handlePhoneLogin();
                  }}
                >
                  Resend OTP
                </button>
              ) : (
                <div>
                  Resend otp in{" "}
                  <span style={{ padding: "0 2px", fontWeight: "600" }}>
                    {formatSeconds(otpCountDown)}
                  </span>{" "}
                </div>
              )}
              {/* <img src="./book-loader.gif" alt=" Loading . . .  " className={`${(verificationProcess)?'':'in'}active-img`} /> */}
            </div>
          </div>
        </>
      )}

      <div className="reviews">
        <div>
          <div>
            <img src="./google-icon.svg" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
              Google
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.8</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>1,013+ </span>
            reviews
          </div>
        </div>

        <div>
          <div>
            <img src="./jd-icon.svg" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
              JustDail
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.7</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>827+ </span>
            reviews
          </div>
        </div>

        <div>
          <div>
            <img src="./mouth-shut-icon.gif" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.5</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>1177+ </span>
            reviews
          </div>
        </div>

        <div>
          <div>
            <img src="./glassdoor-icon.svg" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
              Glassdoor
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.8</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>50+ </span>
            reviews
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
